import classNames from 'classnames';
import { LinkButton } from 'components/LinkButton';

import * as styles from './styles';

export interface SignatureInformationProps {
  cell:
    | JSX.Element
    | string
    | boolean
    | string[]
    | number
    | Record<string, unknown>;
  className?: string;
}

const SignatureInformation = ({
  cell,
  className,
}: SignatureInformationProps) => (
  <span className={classNames(className, styles.rowData)}>
    <img src={cell as string} alt="" width={128} />

    <LinkButton
      href={cell as string}
      download="signature.png"
      buttonType="secondary"
    >
      Download signature
    </LinkButton>
  </span>
);

export default SignatureInformation;
