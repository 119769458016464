import { ApolloError } from '@apollo/client';
import { capitalizeName } from '@getpopsure/public-utility';
import { ActivityLog } from 'components/ActivityLog/ActivityLog';
import { DetailsPage } from 'components/DetailsPage';
import { ExternalLink } from 'components/DetailsPage/models';
import { retrieveExternalLinks } from 'components/DetailsPage/utils';
import { NotesTab } from 'components/Notes/NotesTab';
import { TabInformation } from 'components/Tabs';
import { Note } from 'models/Note';
import { PaymentMethod } from 'models/PaymentMethod';
import { Policy } from 'models/policy';
import { User } from 'models/User';

import { OverviewTab } from './components/OverviewTab';
import { GET_CUSTOMER } from './graphql/queries';

const noNamePlaceholder = 'No name';

interface Props {
  userId: string;
  intercomUrl?: string;
  stripeUrl?: string;
  customer?: User;
  customerLoading: boolean;
  policies?: Policy[];
  paymentMethods?: PaymentMethod[];
  pinnedNotes?: Note[];
  error?: ApolloError;
  stripeVisibilityUrl?: string;
  customerJourneyUrl?: string;
  customerIoUrl?: string;
}

export const CustomerDetailsView = ({
  intercomUrl,
  stripeUrl,
  policies,
  customer,
  customerLoading,
  paymentMethods,
  pinnedNotes,
  error,
  stripeVisibilityUrl,
  customerJourneyUrl,
  customerIoUrl,
}: Props) => {
  const customerName =
    customer?.firstName && customer?.lastName
      ? capitalizeName({
          firstName: customer.firstName,
          lastName: customer.lastName,
        }) ?? noNamePlaceholder
      : noNamePlaceholder;

  const externalLinks: ExternalLink[] = retrieveExternalLinks([
    { title: 'Intercom', href: intercomUrl },
    { title: 'Stripe', href: stripeUrl },
    { title: 'Stripe visibility', href: stripeVisibilityUrl },
    { title: 'Customer journey', href: customerJourneyUrl },
    { title: 'Emails', href: customerIoUrl },
  ]);

  const tabs: TabInformation[] = [
    {
      name: 'Overview',
      href: '#overview',
      withDot: false,
    },
    {
      name: 'Activity',
      href: '#activity',
      withDot: false,
    },
    {
      name: 'Notes',
      href: '#notes',
      withDot: false,
    },
  ];

  const tabComponents = [
    {
      id: 'overview',
      component: OverviewTab,
      props: {
        policies,
        customer,
        customerLoading,
        paymentMethods,
        pinnedNotes,
      },
    },
    {
      id: 'activity',
      component: ActivityLog,
      props: {
        where: [
          { table: 'user', field: 'id', value: customer?.id },
          {
            table: 'admin_notes',
            field: 'user_id',
            value: customer?.id,
            returnFields: ['body'],
          },
        ],
      },
    },
    {
      id: 'notes',
      component: NotesTab,
      props: {
        type: 'USER',
        entityId: customer?.id,
        refetchQueries: [GET_CUSTOMER],
      },
    },
  ];

  return (
    <DetailsPage
      windowTitle={`${customerName} - Feather Admin Panel`}
      pageTitle={customerName}
      backLink={{ text: 'Back' }}
      externalLinks={externalLinks}
      tabs={tabs}
      tabComponents={tabComponents}
      loading={customerLoading}
      current="customers"
      error={error}
    />
  );
};
