import classNames from 'classnames';
import React from 'react';
import { useHistory } from 'react-router-dom';

import * as styles from './styles';

type LinkType = 'primary' | 'secondary' | 'grey';

interface LinkProps extends React.ComponentPropsWithRef<'a'> {
  color: LinkType;
  text?: string;
  IconComponent?: React.ComponentType<any>;
  disabled?: boolean;
  iconLeft?: boolean;
}

const Link = React.forwardRef(
  (
    {
      color,
      text,
      IconComponent,
      iconLeft = true,
      disabled,
      className,
      href,
      ...props
    }: LinkProps,
    ref?: React.ForwardedRef<HTMLAnchorElement>
  ) => {
    /**
     * TODO: [KONG] Replace with useNavigation when upgrading react-router to v6
     */
    const history = useHistory();

    if (!href) {
      return (
        <button
          type="button"
          className={classNames(className, styles.link, {
            [styles.linkPrimary]: color === 'primary' && !disabled,
            [styles.linkSecondary]:
              (color === 'secondary' || color === 'grey') && !disabled,
            [styles.linkPrimaryDisabled]:
              (color === 'primary' || color === 'grey') && disabled,
            [styles.linkSecondaryDisabled]: color === 'secondary' && disabled,
          })}
          onClick={(e) => {
            e.preventDefault();
            history.goBack();
          }}
        >
          {IconComponent && iconLeft && (
            <IconComponent
              className={classNames(styles.icon, {
                [styles.iconPrimary]: color === 'primary' && !disabled,
                [styles.iconGrey]: color === 'grey' && !disabled,
                [styles.iconPrimaryDisabled]: color === 'primary' && disabled,
                [styles.iconGreyDisabled]: color === 'grey' && disabled,
              })}
            />
          )}
          {text && <p>{text}</p>}
          {IconComponent && !iconLeft && (
            <IconComponent
              className={classNames(styles.icon, {
                [styles.iconPrimary]: color === 'primary' && !disabled,
                [styles.iconGrey]: color === 'grey' && !disabled,
                [styles.iconPrimaryDisabled]: color === 'primary' && disabled,
                [styles.iconGreyDisabled]: color === 'grey' && disabled,
              })}
            />
          )}
        </button>
      );
    }

    return (
      <a
        href={href}
        {...props}
        ref={ref}
        className={classNames(className, styles.link, {
          [styles.linkPrimary]: color === 'primary' && !disabled,
          [styles.linkSecondary]:
            (color === 'secondary' || color === 'grey') && !disabled,
          [styles.linkPrimaryDisabled]:
            (color === 'primary' || color === 'grey') && disabled,
          [styles.linkSecondaryDisabled]: color === 'secondary' && disabled,
        })}
        data-testid={`link-anchor-${text}`}
      >
        {IconComponent && iconLeft && (
          <IconComponent
            className={classNames(styles.icon, {
              [styles.iconPrimary]: color === 'primary' && !disabled,
              [styles.iconGrey]: color === 'grey' && !disabled,
              [styles.iconPrimaryDisabled]: color === 'primary' && disabled,
              [styles.iconGreyDisabled]: color === 'grey' && disabled,
            })}
          />
        )}
        {text && <p>{text}</p>}
        {IconComponent && !iconLeft && (
          <IconComponent
            className={classNames(styles.icon, {
              [styles.iconPrimary]: color === 'primary' && !disabled,
              [styles.iconGrey]: color === 'grey' && !disabled,
              [styles.iconPrimaryDisabled]: color === 'primary' && disabled,
              [styles.iconGreyDisabled]: color === 'grey' && disabled,
            })}
          />
        )}
      </a>
    );
  }
);

export default Link;
