import {
  DocumentNode,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client';
import { useTranslation } from '@getpopsure/i18n-react';
import { capitalizeName } from '@getpopsure/public-utility';
import { UserIcon } from '@heroicons/react/solid';
import { ActivityLog } from 'components/ActivityLog/ActivityLog';
import Badge from 'components/Badge';
import ChangeStatus from 'components/ChangeStatus/ChangeStatus';
import { DetailsPage } from 'components/DetailsPage';
import {
  ExternalLink,
  HeaderInfoText,
  InternalLink,
  TabComponent,
} from 'components/DetailsPage/models';
import { NotesTab } from 'components/Notes/NotesTab';
import { TabInformation } from 'components/Tabs';
import { GET_COMPANIES } from 'graphql/admin/companies/queries';
import { DataCompanies } from 'pages/admin/users/types';
import { isGenericInsuranceType } from 'pages/policies/generic/models/GenericInsuranceType';
import { useEffect, useState } from 'react';
import { generatePath, useParams } from 'react-router';
import { paths } from 'routes/definedPaths';
import { countryIconMapper } from 'shared/countries/models';
import { alertBanners, setNewAlertBanner } from 'shared/reactiveVariables';
import { AlertBannerState } from 'shared/reactiveVariables/models';
import { getIntercomUrl } from 'shared/utils/getIntercomUrl';
import { getStripeUrl } from 'shared/utils/getStripeUrl';
import { v4 as uuidv4 } from 'uuid';

import { ArchivePolicy } from '../ArchivePolicy';
import {
  HRTemplateInsuranceType,
  PolicyTemplateInsurancePath,
  TemplateInsuranceType,
} from '../models/InsuranceTypes';
import { PolicyTemplateData } from '../models/PolicyTemplateData';
import { ARCHIVE_POLICY, UNARCHIVE_POLICY } from '../mutations';
import OverviewTab from '../OverviewTab/OverviewTab';
import QuestionnaireTab from '../QuestionnaireTab/QuestionnaireTab';
import SideSection from '../SideSection/SideSection';
import {
  getOtherPolicies,
  getPolicyData,
  isArchivableInsurance,
} from '../utils';

const PolicyDetailsTemplate = ({
  templateData,
  insuranceType,
  policyId,
}: {
  templateData: PolicyTemplateData<any> & {
    queries: { policy: DocumentNode };
  };
  insuranceType: TemplateInsuranceType | HRTemplateInsuranceType;
  policyId: string;
}) => {
  const { navigation, features, copy, queries, mutations } = templateData;

  const { t } = useTranslation();

  const { insuranceType: insurancePath } = useParams<{
    insuranceType: string;
  }>();

  const {
    loading: policyFetchLoading,
    error: policyFetchError,
    data: policyData,
  } = useQuery(queries.policy, {
    variables: {
      id: policyId,
      ...features.details?.queryVariables,
    },
  });

  const [companiesQuery, setCompaniesQuery] = useState('');

  const { data: dataCompanies } = useQuery<DataCompanies>(GET_COMPANIES, {
    variables: {
      limit: 25,
      offset: 0,
      searchString: companiesQuery,
      sortColumn: '',
      sortOrder: '',
      filterType: 'any',
    },
    notifyOnNetworkStatusChange: false,
    errorPolicy: 'none',
  });

  useEffect(() => {
    if (policyData?.genericPolicy) {
      setCompaniesQuery(policyData?.genericPolicy?.company?.name ?? '');
    }
  }, [policyData]);

  const alertBannersState = useReactiveVar(alertBanners);

  const onArchiveOrUnarchiveComplete = (isArchived: boolean) => {
    const message = isArchived
      ? 'Policy successfully archived.'
      : 'Policy successfully unarchived.';

    const newAlertBanner: AlertBannerState = {
      id: uuidv4(),
      type: 'SUCCESS',
      message,
    };

    setNewAlertBanner({ state: alertBannersState, newAlertBanner });
  };

  const onArchiveOrUnarchiveError = () => {
    const newAlertBanner: AlertBannerState = {
      id: uuidv4(),
      type: 'WARNING',
      message: 'Something went wrong. Please try again.',
    };

    setNewAlertBanner({ state: alertBannersState, newAlertBanner });
  };

  const [
    archivePolicy,
    { loading: archivePolicyLoading, error: archivePolicyError },
  ] = useMutation(ARCHIVE_POLICY, {
    variables: {
      insuranceType,
      id: policyId,
    },
    refetchQueries: [queries.policy],
    errorPolicy: 'none',
    onCompleted: () => onArchiveOrUnarchiveComplete(true),
    onError: onArchiveOrUnarchiveError,
  });

  const [
    unarchivePolicy,
    { loading: unarchivePolicyLoading, error: unarchivePolicyError },
  ] = useMutation(UNARCHIVE_POLICY, {
    variables: {
      insuranceType,
      id: policyId,
    },
    refetchQueries: [queries.policy],
    errorPolicy: 'none',
    onCompleted: () => onArchiveOrUnarchiveComplete(false),
    onError: onArchiveOrUnarchiveError,
  });

  const policy = getPolicyData(insuranceType, policyData);

  const internalLinks: InternalLink[] = [
    {
      title: `Account: ${capitalizeName({
        firstName: policy?.user?.firstName ?? '',
        lastName: policy?.user?.lastName ?? '',
      })}`,
      icon: UserIcon,
      href: policyData
        ? generatePath(paths.customers.details.path, {
            id: policy?.user?.id,
          })
        : '',
    },
    ...(features.details?.internalLinks ?? []),
  ];

  const externalLinks: ExternalLink[] = [
    ...(policy?.user?.intercomEUId
      ? [
          {
            title: 'Intercom',
            href: getIntercomUrl(policy.user.intercomEUId) ?? '',
          },
        ]
      : []),
    ...(policy?.user?.stripeToken
      ? [
          {
            title: 'Stripe',
            href: getStripeUrl(policy.user.stripeToken) ?? '',
          },
        ]
      : []),
    ...(features.details?.externalLinks ?? []),
  ];

  const tabs: TabInformation[] = [
    {
      name: t('admin.details.tab.overview.label', 'Overview'),
      href: '#overview',
      withDot: false,
    },
    {
      name: 'Questionnaire',
      href: '#questionnaire',
      withDot: false,
    },

    ...(features.details?.additionalTabs ?? []),
    {
      name: 'Activity',
      href: '#activity',
      withDot: false,
    },
    {
      name: 'Notes',
      href: '#notes',
      withDot: false,
    },
  ];

  const sideSectionComponent = (
    <SideSection
      id={policy?.id ?? ''}
      userId={policy?.user?.id ?? ''}
      activity={policy?.activity ?? []}
      insuranceType={
        isGenericInsuranceType(insuranceType)
          ? 'generic'
          : (insurancePath as PolicyTemplateInsurancePath)
      }
      documentsData={{
        sections: features.documents?.getDocumentSections(policyData) ?? [],
        options: features.documents?.uploadOptions ?? [],
        uploadMutation: mutations.uploadFile,
        getPolicyQuery: queries.policy,
        additionalQueries: features.documents?.additionalQueryVariables,
        deleteFileMutation: mutations.deleteFile,
      }}
      policies={getOtherPolicies(insuranceType, policyData) ?? undefined}
      pinnedNotes={policy?.pinnedNotes}
      refetchQueries={[queries.policy]}
      hrPage={false}
    />
  );

  const questionnaireData =
    features.questionnaire?.getQuestionnaireData(policyData);

  const tabComponents: TabComponent[] = [
    {
      id: 'overview',
      component: OverviewTab,
      props: {
        cards: features.details?.overviewTab.getCards(
          policyData,
          dataCompanies?.companies,
          setCompaniesQuery
        ),
        sideSection: sideSectionComponent,
        policyData: policy,
      },
    },
    ...(questionnaireData && features.questionnaire
      ? [
          {
            id: 'questionnaire',
            component: QuestionnaireTab,
            props: {
              questionnaireId: questionnaireData.id,
              answers: questionnaireData.answers,
              cardTitle: features.questionnaire.cardTitle,
              policyId: policy?.id,
              editable: features.questionnaire.editable,
              mutation: mutations.editQuestionnaire,
              editDescription: features.questionnaire.editDescription,
              sideSection: sideSectionComponent,
              refetchQueries: [queries.policy],
            },
          },
        ]
      : []),
    ...(features.details?.additionalTabComponents ?? []),
    {
      id: 'activity',
      component: ActivityLog,
      props: {
        where: [
          { table: 'user_policies', field: 'id', value: policy?.id },
          { table: 'user', field: 'id', value: policy?.user?.id },
          ...(questionnaireData?.id
            ? [
                {
                  table: 'questionnaires',
                  field: 'id',
                  value: questionnaireData.id,
                },
              ]
            : []),
        ],
      },
    },
    {
      id: 'notes',
      component: NotesTab,
      props: {
        type: 'POLICY',
        entityId: policy?.id,
        refetchQueries: [queries.policy],
      },
    },
  ];

  const policyRegion = policy?.regionOfPurchase;

  const currentStatusOption =
    features.statusChange?.statusOptions.find(
      (statusOption) => statusOption.id === policy?.publicStatus
    ) ?? features.statusChange?.statusOptions[0];

  const loading =
    policyFetchLoading || archivePolicyLoading || unarchivePolicyLoading;
  const error = policyFetchError || archivePolicyError || unarchivePolicyError;

  const isArchived = Boolean(policy?.archivedAt);

  const infoTexts: HeaderInfoText[] = [
    ...(features.details?.infoTexts ? features.details.infoTexts : []),
    ...(policyRegion
      ? [
          {
            title: policyRegion.toUpperCase(),
            icon: (
              <img
                className="rounded-sm"
                src={countryIconMapper[policyRegion]}
                alt={policyRegion}
              />
            ),
          },
        ]
      : []),
  ];

  return (
    <DetailsPage
      windowTitle={copy.details.pageTitle}
      pageTitle={copy.details.title}
      pageSubtitle={features.details?.insuredPersonName(policyData)}
      backLink={{
        text: t('admin.details.back.link.label', 'Back'),
      }}
      loading={loading}
      tabs={tabs}
      tabComponents={tabComponents}
      current={navigation.current}
      internalLinks={internalLinks}
      externalLinks={externalLinks}
      headerInfoTexts={infoTexts}
      hasLanguageSelector={features.languageSelector}
      error={error}
      headerActions={
        features.statusChange &&
        mutations.statusChange &&
        currentStatusOption && (
          <div className="flex items-center justify-between">
            <ChangeStatus
              status={currentStatusOption}
              policyId={policyId}
              statusOptions={features.statusChange.statusOptions}
              modals={features.statusChange.getModals(policyData)}
              mutation={mutations.statusChange}
              mutationOperationVariables={{
                refetchQueries: [queries.policy],
                awaitRefetchQueries: true,
                errorPolicy: 'none',
              }}
              statusChangeOptions={features.statusChange.statusChangeOptions}
              mutationVariables={features.statusChange.additionalQueryVariables}
              retrieveModalId={features.statusChange.retrieveModalId}
              extendedValidatorObject={
                features.statusChange.extendedValidatorObject
              }
            />
            {isArchivableInsurance(insuranceType) && (
              <ArchivePolicy
                onArchive={archivePolicy}
                onUnarchive={unarchivePolicy}
                shouldArchivePolicy={!isArchived}
              />
            )}
          </div>
        )
      }
      titleBadge={
        isArchived && (
          <Badge color="gray" badgeType="full">
            Archived
          </Badge>
        )
      }
    />
  );
};

export default PolicyDetailsTemplate;
