import { useLazyQuery, useMutation } from '@apollo/client';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { ExternalLinkIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import Badge from 'components/Badge';
import Button from 'components/Button';
import { CheckBox } from 'components/CheckBox';
import FileErrorCard from 'components/FileErrorCard';
import { NumberInput } from 'components/Input/NumberInput';
import Link from 'components/Link';
import Modal from 'components/Modal';
import { PolicyCard } from 'components/PolicyCard';
import SelectButton, { SelectButtonOption } from 'components/SelectButton';
import hashSum from 'hash-sum';
import useStateWithLocalStorage from 'hooks/useStateWithLocalStorage';
import {
  UPDATE_SCANNED_DOCUMENT,
  UPDATE_SCANNED_DOCUMENTS_USER_CLAIMS_RELATION,
} from 'pages/scannedDocuments/scannedDocuments.mutations';
import {
  GET_CLAIMS_BY_SIMILAR_POLICY_NUMBER,
  GET_SCANNED_DOCUMENT_BY_ID,
} from 'pages/scannedDocuments/scannedDocuments.queries';
import {
  ClaimData,
  RelatedClaimsData,
  ScannedDocument,
} from 'pages/scannedDocuments/scannedDocuments.types';
import {
  getClaimDetailsBaseURLFromInsuranceType,
  getPolicyDetailsBaseURLFromInsuranceType,
} from 'pages/scannedDocuments/scannedDocuments.utils';
import { ChangeEvent, useEffect, useState } from 'react';
import { dentalClaimStatuses } from 'shared/claims/dental/models';
import { expatClaimStatuses } from 'shared/claims/expatHealth/models';
import { expatSpainClaimStatuses } from 'shared/claims/expatSpain/models';
import { genericClaimStatuses } from 'shared/claims/generic/models';
import { householdClaimStatuses } from 'shared/claims/household/models';
import { liabilityClaimStatuses } from 'shared/claims/liability/models';
import {
  combinedClaimStatusMapper,
  combinedClaimTypeMapper,
  combinedStatusColorMapper,
} from 'shared/claims/mappers';
import { CombinedClaimStatus } from 'shared/claims/models';
import { privateHealthClaimStatuses } from 'shared/claims/privateHealth/models';
import { insuranceIconMapper } from 'shared/insurances/insuranceIconMapper';
import { insuranceNameMapper } from 'shared/insurances/insuranceNameMapper';

import * as styles from './ScannedDocument.AttachToClaim.styles';

type ClaimStatus = CombinedClaimStatus | 'KEEP_UNCHANGED';
type SelectedClaimStatusWithRefundAmount = SelectButtonOption<ClaimStatus> & {
  refundAmount?: number;
};

const getPreselectedClaims = (
  allClaims: RelatedClaimsData['userClaimsBySimilarPolicyNumber'],
  textContent: string
): Record<string, boolean> | undefined => {
  const claimsIdsWithMatchingAmount = allClaims?.flatMap((claim) => {
    if (
      !claim?.amount ||
      // Exclude "finished" claims
      claim.status === 'CLOSED' || // Private health
      claim.status === 'CUSTOMER_PAID_OUT' // Dental
    ) {
      return [];
    }
    const amountUS = claim.amount.toString();
    const amountDE = amountUS.replace('.', ',');

    const amountMentionedInTextContent =
      textContent.includes(amountUS) || textContent.includes(amountDE);

    return amountMentionedInTextContent ? [claim?.id || ''] : [];
  });

  return claimsIdsWithMatchingAmount?.reduce(
    (preselectedClaimIds, currentClaimId) => ({
      ...preselectedClaimIds,
      [currentClaimId]: true,
    }),
    {}
  );
};

const sortByCreatedAtDescending = (a: ClaimData, b: ClaimData) => {
  if (a?.createdAt && b?.createdAt) {
    return b.createdAt.localeCompare(a.createdAt);
  }
  return 0;
};

export const AttachToClaim = ({
  scannedDocument,
}: {
  scannedDocument: ScannedDocument;
}) => {
  const { policyNumber } = scannedDocument;

  const [showModal, setShowModal] = useState(false);
  const [refundForEveryClaim, setRefundForEveryClaim] = useState(false);

  const {
    value: showExactMatchesOnlyPref = 'true',
    setValue: setShowExactMatchesOnlyPref,
  } = useStateWithLocalStorage('SD:showExactMatchesOnly');

  const showExactMatchesOnly = showExactMatchesOnlyPref === 'true';

  const {
    value: sameStatusForAllClaimsPref = 'true',
    setValue: setSameStatusForAllClaimsPref,
  } = useStateWithLocalStorage('SD:sameStatusForAllClaims');

  const sameStatusForAllClaims = sameStatusForAllClaimsPref === 'true';

  const [getRelatedClaims, getRelatedClaimsResult] =
    useLazyQuery<RelatedClaimsData>(GET_CLAIMS_BY_SIMILAR_POLICY_NUMBER);

  const matchingPolicy =
    scannedDocument.userPolicy ?? scannedDocument.relatedUserPolicy;
  const unsortedRelatedClaims =
    getRelatedClaimsResult.data?.userClaimsBySimilarPolicyNumber;

  const relatedClaims = unsortedRelatedClaims
    ? [...unsortedRelatedClaims].sort(sortByCreatedAtDescending)
    : undefined;

  const insuranceType = matchingPolicy?.insuranceType;

  let possibleClaimStatuses;

  switch (insuranceType) {
    case 'PRIVATE_HEALTH':
      possibleClaimStatuses = privateHealthClaimStatuses;
      break;
    case 'HOUSEHOLD':
      possibleClaimStatuses = householdClaimStatuses;
      break;
    case 'DENTAL':
      possibleClaimStatuses = dentalClaimStatuses;
      break;
    case 'INCOMING':
      possibleClaimStatuses = expatClaimStatuses;
      break;
    case 'INCOMING_ES':
      possibleClaimStatuses = expatSpainClaimStatuses;
      break;
    case 'INCOMING_LT':
      possibleClaimStatuses = genericClaimStatuses;
      break;
    case 'LIABILITY':
      possibleClaimStatuses = liabilityClaimStatuses;
      break;
    case 'PET_HEALTH':
      possibleClaimStatuses = genericClaimStatuses;
      break;
    default:
      possibleClaimStatuses = [];
      break;
  }

  let claimStatusOptions: SelectButtonOption<
    CombinedClaimStatus | 'KEEP_UNCHANGED'
  >[] = (possibleClaimStatuses as unknown as CombinedClaimStatus[])?.map(
    (statusId) => ({
      id: statusId,
      label: combinedClaimStatusMapper[statusId] ?? '',
      title: combinedClaimStatusMapper[statusId] ?? '',
      color: combinedStatusColorMapper[statusId],
    })
  );

  claimStatusOptions = [
    {
      id: 'KEEP_UNCHANGED',
      label: '- Keep unchanged -',
      title: '- Keep unchanged -',
    },
    ...claimStatusOptions,
  ];

  const [statusForAllClaims, setStatusForAllClaims] =
    useState<SelectedClaimStatusWithRefundAmount>(claimStatusOptions[0]);

  const [statusForClaimById, setStatusForClaimById] = useState<
    Record<string, SelectedClaimStatusWithRefundAmount>
  >({});

  const preSelectedClaims = getPreselectedClaims(
    showExactMatchesOnly
      ? relatedClaims?.filter(
          (c) => c.userPolicies?.policyNumber === scannedDocument.policyNumber
        )
      : relatedClaims,
    scannedDocument.textContent ?? ''
  );

  const [selectedClaims, setSelectedClaims] = useState<Record<string, boolean>>(
    {}
  );

  const connectedClaimsData =
    relatedClaims?.flatMap(({ id = '' }) => {
      const claimWasSelected = selectedClaims[id];

      if (claimWasSelected) {
        const selectedNewClaimStatus = sameStatusForAllClaims
          ? statusForAllClaims.id
          : statusForClaimById[id]?.id ?? 'KEEP_UNCHANGED';

        const newClaimStatus =
          selectedNewClaimStatus !== 'KEEP_UNCHANGED'
            ? selectedNewClaimStatus
            : undefined;

        const refundAmount = statusForClaimById[id]?.refundAmount;

        return [
          {
            claimId: id,
            ...(newClaimStatus ? { newClaimStatus } : {}),
            ...(refundAmount ? { refundAmount } : {}),
          },
        ];
      }

      return [];
    }) ?? [];

  const [changeRelatedClaims, changeRelatedClaimsResult] = useMutation(
    UPDATE_SCANNED_DOCUMENTS_USER_CLAIMS_RELATION,
    {
      variables: {
        scannedDocumentId: scannedDocument.id,
        connectedClaimsData,
      },
    }
  );

  const [setDocumentPolicyIdAndDone, setDocumentPolicyIdAndDoneResult] =
    useMutation(UPDATE_SCANNED_DOCUMENT, {
      variables: {
        id: scannedDocument.id,
        userPolicyId: matchingPolicy?.id,
        status: 'DONE',
      },
      refetchQueries: [GET_SCANNED_DOCUMENT_BY_ID],
    });

  const handleConfirm = async () => {
    await changeRelatedClaims();
    await setDocumentPolicyIdAndDone();
    if (
      !changeRelatedClaimsResult.error &&
      !setDocumentPolicyIdAndDoneResult.error
    ) {
      setShowModal(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (matchingPolicy?.id) {
        await getRelatedClaims({
          variables: {
            policyNumber: scannedDocument.policyNumber,
          },
        });
      }
    };

    fetchData();
  }, [
    matchingPolicy?.id,
    hashSum(relatedClaims),
    scannedDocument.policyNumber,
    getRelatedClaims,
  ]);

  const confirmationRequired = matchingPolicy && !scannedDocument.userPolicyId;

  const archived = !!scannedDocument.archivedAt;

  const handleChangePaidOutAmount =
    (claimId?: string) => (event: ChangeEvent<HTMLInputElement>) => {
      if (!claimId) {
        throw new Error(
          'Scanned docs - Trying to update connected claim without Claim ID'
        );
      }

      setStatusForClaimById((state) => ({
        ...state,
        [claimId]: {
          ...state[claimId],
          refundAmount: parseFloat(event.target.value),
        },
      }));
    };

  if (!relatedClaims?.length) {
    return null;
  }

  return (
    <>
      {showModal && (
        <Modal
          title="Connect document to claim(s)"
          open={showModal}
          setOpen={setShowModal}
          confirmButtonLabel="Connect"
          handleConfirm={handleConfirm}
          scrollable
        >
          <div className="flex flex-col">
            {confirmationRequired && (
              <FileErrorCard
                open={true}
                className="mb-5 mt-5"
                title="Unconfirmed relation"
                hideCloseButton={true}
                handleClose={() => {}}
                errorType="WARNING"
                description="Accepting this action will also confirm that below policy belongs to this document."
              />
            )}

            <p className={classNames(styles.label, 'mt-5')}>Matching policy</p>
            <PolicyCard
              title={
                (matchingPolicy?.insuranceType &&
                  insuranceNameMapper[matchingPolicy?.insuranceType]) ??
                ''
              }
              icon={
                (matchingPolicy?.insuranceType &&
                  insuranceIconMapper[matchingPolicy.insuranceType]) ??
                ''
              }
              link={
                getPolicyDetailsBaseURLFromInsuranceType(
                  matchingPolicy?.insuranceType,
                  matchingPolicy?.id
                ) ?? ''
              }
              insuredPerson={`${matchingPolicy?.user.firstName} ${matchingPolicy?.user.lastName}`}
              policyNumber={matchingPolicy?.policyNumber}
              regionOfPurchase={matchingPolicy?.regionOfPurchase}
              smallVersion
              openInNewTab
            />

            <Link
              color="primary"
              text="Document link"
              href={scannedDocument.url}
              IconComponent={ExternalLinkIcon}
              iconLeft={false}
              target="_blank"
              className="mb-6"
            />
            {claimStatusOptions?.length > 0 && (
              <>
                <div
                  className={classNames(
                    'flex items-center justify-between gap-2',
                    {
                      'opacity-40': !sameStatusForAllClaims,
                    }
                  )}
                >
                  <p className={classNames(styles.label, 'mb-0')}>
                    New claim status
                  </p>
                  <SelectButton
                    withDot={true}
                    options={claimStatusOptions}
                    selected={statusForAllClaims}
                    label="New status"
                    handleOnChange={setStatusForAllClaims}
                    disabled={!sameStatusForAllClaims}
                    showBorder
                  />
                </div>
                <CheckBox
                  checked={sameStatusForAllClaims}
                  setChecked={(isChecked: boolean) =>
                    setSameStatusForAllClaimsPref(isChecked ? 'true' : 'false')
                  }
                  label="Same status for all claims"
                  small
                  className="mt-3 text-sm text-gray-600"
                />
                {sameStatusForAllClaims && (
                  <CheckBox
                    checked={refundForEveryClaim}
                    setChecked={(isChecked: boolean) =>
                      setRefundForEveryClaim(isChecked)
                    }
                    label="Set refund amounts for every claim"
                    small
                    className="mt-3 text-sm text-gray-600"
                  />
                )}
              </>
            )}
            <p className={classNames(styles.label, 'mt-8', 'mb-5')}>
              Select claims
            </p>
            <CheckBox
              checked={showExactMatchesOnly}
              setChecked={(isChecked: boolean) =>
                setShowExactMatchesOnlyPref(isChecked ? 'true' : 'false')
              }
              label="Show exact policy number matches only"
              small
              className="text-sm text-gray-600"
            />
            <div className="mb-6 mt-3 flex flex-col items-stretch gap-3">
              {relatedClaims?.map((claim) => {
                const isExactPolicyNumberMatch =
                  claim.userPolicies?.policyNumber ===
                  matchingPolicy?.policyNumber;

                if (showExactMatchesOnly && !isExactPolicyNumberMatch) {
                  return null;
                }

                return (
                  <div className="mb-4 flex gap-2" key={claim.id}>
                    <div className="flex w-full flex-col">
                      <a
                        className={classNames(styles.claimCard, {
                          [styles.selected]: selectedClaims[claim.id ?? ''],
                        })}
                        href={
                          getClaimDetailsBaseURLFromInsuranceType(
                            matchingPolicy?.insuranceType,
                            claim.id
                          ) ?? ''
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div key={claim.id} className={styles.cardContent}>
                          <div>
                            <p
                              className={classNames(
                                {
                                  'text-gray-500': !isExactPolicyNumberMatch,
                                },
                                'mb-2'
                              )}
                            >
                              {`${claim.claimType
                                ?.map(
                                  ({ type }) => combinedClaimTypeMapper[type]
                                )
                                .join(', ')}`}
                            </p>
                            {!isExactPolicyNumberMatch && (
                              <p className="mb-2 text-gray-500">
                                {claim.userPolicies?.policyNumber}
                              </p>
                            )}
                            {claim.status && (
                              <Badge
                                color={
                                  combinedStatusColorMapper[claim.status] ??
                                  'gray'
                                }
                                badgeType="full"
                              >
                                {combinedClaimStatusMapper[claim.status]}
                              </Badge>
                            )}
                          </div>
                          <p className={styles.amountText}>
                            {`${
                              claim.amount
                                ? englishFormattedEuroCurrency(
                                    claim.amount,
                                    true
                                  )
                                : '€ ---'
                            }`}
                          </p>
                        </div>
                      </a>
                      {selectedClaims[claim.id ?? ''] &&
                        !sameStatusForAllClaims && (
                          <>
                            <div className="mt-2 flex items-center justify-between gap-2 rounded-md border border-indigo-200 p-3">
                              <p className="text-sm text-gray-600">
                                New claim status
                              </p>
                              <SelectButton
                                withDot={true}
                                options={claimStatusOptions}
                                selected={
                                  statusForClaimById[claim.id ?? ''] ??
                                  claimStatusOptions[0]
                                }
                                label="New status"
                                handleOnChange={(
                                  newOption: SelectButtonOption<ClaimStatus>
                                ) =>
                                  setStatusForClaimById((current) => ({
                                    ...current,
                                    [claim.id ?? '']: newOption,
                                  }))
                                }
                                disabled={false}
                              />
                            </div>
                            {statusForClaimById[claim.id ?? '']?.id ===
                              'FEATHER_PAID_OUT' && (
                              <div className="mt-2 flex items-center justify-between gap-2 rounded-md border border-indigo-200 p-3">
                                <label
                                  htmlFor={`${claim.id}-paid-out-amount-input`}
                                  className="text-sm text-gray-600"
                                >
                                  Payout amount
                                </label>
                                <NumberInput
                                  id={`${claim.id}-paid-out-amount-input`}
                                  color="gray"
                                  placeholder="€ 0.00"
                                  onChange={handleChangePaidOutAmount(claim.id)}
                                  value={
                                    statusForClaimById[claim.id || '']
                                      ?.refundAmount
                                  }
                                />
                              </div>
                            )}
                          </>
                        )}
                      {sameStatusForAllClaims && refundForEveryClaim && (
                        <div className="mt-2 flex items-center justify-between gap-2 rounded-md border border-indigo-200 p-3">
                          <label
                            htmlFor={`${claim.id}-paid-out-amount-input`}
                            className="text-sm text-gray-600"
                          >
                            Payout amount
                          </label>
                          <NumberInput
                            id={`${claim.id}-paid-out-amount-input`}
                            color="gray"
                            placeholder="€ 0.00"
                            onChange={handleChangePaidOutAmount(claim.id)}
                            value={
                              statusForClaimById[claim.id || '']?.refundAmount
                            }
                          />
                        </div>
                      )}
                    </div>

                    <CheckBox
                      checked={selectedClaims[claim.id ?? '']}
                      setChecked={(isSelected: boolean) =>
                        setSelectedClaims({
                          ...selectedClaims,
                          [claim.id ?? '']: isSelected,
                        })
                      }
                    />
                  </div>
                );
              })}
            </div>
            {Object.keys(preSelectedClaims || {}).length > 0 && (
              <>
                <Button
                  type="button"
                  buttonType="secondary"
                  onClick={() => setSelectedClaims(preSelectedClaims || {})}
                >
                  Click to auto-select matching claims
                </Button>
              </>
            )}
            <p className={styles.bottomTextBold}>
              Once confirmed, this document will be visible to the user on the
              connected claim details pages.
            </p>
          </div>
        </Modal>
      )}
      <Button
        buttonType="primary"
        disabled={!policyNumber || archived}
        onClick={() => setShowModal(true)}
        loading={
          changeRelatedClaimsResult.loading ||
          setDocumentPolicyIdAndDoneResult.loading
        }
      >
        Connect
      </Button>
    </>
  );
};
