import { useMutation, useReactiveVar } from '@apollo/client';
import { useTranslation } from '@getpopsure/i18n-react';
import AcceptedFile from 'components/FileDropzone/AcceptedFile';
import Input from 'components/Input';
import Loader from 'components/Loader';
import Modal from 'components/Modal';
import SelectMenu, { SelectMenuOption } from 'components/SelectMenu';
import { DocumentNode } from 'graphql';
import { useState } from 'react';
import { alertBanners, setNewAlertBanner } from 'shared/reactiveVariables';
import { AlertBannerState } from 'shared/reactiveVariables/models';
import { getMimeType } from 'shared/utils/getMimeType';
import { v4 as uuidv4 } from 'uuid';

import * as styles from './styles';

export const EditButton = ({
  document,
  mutation,
  documentVisibilityOptions,
  refetchQuery,
  displayInternalNotes,
}: {
  document: {
    id: string;
    url: string;
    fileName: string;
    friendlyName?: string;
    uploadType?: string;
    category?: string;
    isVisibleToCustomer?: boolean;
    isVisibleToHr?: boolean;
    isVisibleToProvider?: boolean;
    internalNotes?: string;
  };
  mutation: DocumentNode;
  documentVisibilityOptions?: SelectMenuOption[];
  refetchQuery?: DocumentNode;
  displayInternalNotes?: boolean;
}) => {
  const { t } = useTranslation();
  const alertBannersState = useReactiveVar(alertBanners);
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [updatedDocument, setUpdatedDocument] = useState(document);
  const [nameError, setNameError] = useState(false);

  const [updateDocument, { loading }] = useMutation(mutation, {
    variables: {
      id: updatedDocument.id,
    },
    onCompleted: () => {
      setOpen(false);
      const newAlertBanner: AlertBannerState = {
        id: uuidv4(),
        type: 'SUCCESS',
        message: t(
          'admin.companyDocuments.edit.success.title',
          'Document was edited successfully.'
        ),
      };

      setNewAlertBanner({ state: alertBannersState, newAlertBanner });
    },
    onError: () => {
      setOpen(false);
      const newAlertBanner: AlertBannerState = {
        id: uuidv4(),
        type: 'WARNING',
        message: t(
          'admin.companyDocuments.edit.error.title',
          'Something went wrong. Please try again.'
        ),
      };

      setNewAlertBanner({ state: alertBannersState, newAlertBanner });
    },
    ...(refetchQuery && { refetchQueries: [refetchQuery] }),
  });

  const handleSaveDocument = () => {
    if (!updatedDocument.friendlyName) {
      setNameError(true);
    }

    if (updatedDocument.friendlyName) {
      setNameError(false);
      updateDocument({
        variables: {
          id: updatedDocument.id,
          friendlyName: updatedDocument.friendlyName,
          uploadType: updatedDocument.uploadType,
          category: updatedDocument.category,
          isVisibleToCustomer: updatedDocument.isVisibleToCustomer,
          isVisibleToHr: updatedDocument.isVisibleToHr,
          isVisibleToProvider: updatedDocument.isVisibleToProvider,
          internalNotes: updatedDocument.internalNotes,
        },
      });
    }
  };

  const handleOpenModal = () => {
    fetchBlob();
    setOpen(true);
  };

  const fetchBlob = async () => {
    const response = await fetch(document.url, {
      method: 'GET',
      headers: {
        'Content-Type': getMimeType(document.fileName),
      },
    });
    let blob = await response.blob();
    if (!blob.type) {
      blob = new Blob([blob], {
        type: getMimeType(document.fileName),
      });
    }

    setFile(new File([blob], document.fileName));
  };

  const getVisibility = () => {
    const selected: SelectMenuOption[] = [];
    if (updatedDocument.isVisibleToHr) {
      const hrOption = documentVisibilityOptions?.find(
        (option) => option.id === 'HR'
      );
      if (hrOption) selected.push(hrOption);
    }

    return selected;
  };

  const updateVisibility = (newSelected: SelectMenuOption[]) => {
    const hr = newSelected.find((option) => option.id === 'HR');

    setUpdatedDocument({
      ...updatedDocument,
      isVisibleToHr: !!hr,
    });
  };

  return (
    <>
      <button
        type="button"
        className={styles.dropdownOption}
        onClick={() => handleOpenModal()}
      >
        {t('admin.companyDocuments.action.edit.label', 'Edit')}
      </button>
      <Modal
        title={t('admin.companyDocuments.editModal.title', 'Edit document')}
        open={open}
        setOpen={setOpen}
        confirmButtonLabel={t(
          'admin.companyDocuments.editModal.save.label',
          'Save'
        )}
        loading={loading}
        handleConfirm={handleSaveDocument}
      >
        <div className="flex flex-col space-y-4">
          {file ? (
            <AcceptedFile file={file} download={true} color="gray" />
          ) : (
            <div className="flex justify-center">
              <Loader className="h-[20px] w-[20px] animate-spin" />
            </div>
          )}
          <div className="flex items-center justify-between">
            <label className="text-sm font-bold" htmlFor="name">
              {t('admin.companyDocuments.editModal.name.label', 'Name')}
            </label>
            <Input
              id="name"
              color="gray"
              className="w-[288px]"
              placeholder={t(
                'admin.companyDocuments.editModal.name.placeholder',
                'User-friendly name'
              )}
              value={updatedDocument.friendlyName}
              onChange={(e) =>
                setUpdatedDocument({
                  ...updatedDocument,
                  friendlyName: e.target.value,
                })
              }
              error={nameError}
              errorMessage={t(
                'admin.companyDocuments.editModal.error.label',
                'This field is required'
              )}
            />
          </div>
          {documentVisibilityOptions && (
            <div>
              <SelectMenu
                color="gray"
                options={documentVisibilityOptions}
                selected={getVisibility()}
                setSelected={updateVisibility}
                placeholder={t(
                  'admin.companyDocuments.editModal.visibility.placeholder',
                  'Visibility option(s)'
                )}
                label={t(
                  'admin.companyDocuments.editModal.visibility.label',
                  'Visibility'
                )}
                horizontal
                className="w-[288px]"
                multiple
              />
            </div>
          )}
          {displayInternalNotes && (
            <div className="flex items-center justify-between">
              <label className="text-sm font-bold" htmlFor="notes">
                {t('admin.companyDocuments.editModal.notes.label', 'Notes')}
              </label>
              <Input
                id="notes"
                color="gray"
                className="w-[288px]"
                placeholder={t(
                  'admin.companyDocuments.editModal.notes.placeholder',
                  'Internal notes'
                )}
                value={updatedDocument.internalNotes}
                onChange={(e) =>
                  setUpdatedDocument({
                    ...updatedDocument,
                    internalNotes: e.target.value,
                  })
                }
              />
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};
