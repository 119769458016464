import { capitalizeName } from '@getpopsure/public-utility';
import { PolicyTemplateData } from 'pages/policies/template/models/PolicyTemplateData';
import { policyDocumentCategories } from 'shared/policyDocumentCategories';

import { NUMBER_OF_POLICIES_PER_PAGE } from '../template/constants';
import { getDocumentSections } from '../template/defaultPolicyDocumentsConfig';
import { TemplateInsuranceType } from '../template/models/InsuranceTypes';
import { getDbInsuranceType } from '../template/utils';
import { getOverviewTabCards } from './data/overviewTabData';
import { getQuestionnaireData } from './data/questionnaireData';
import {
  extendedValidatorObject,
  getModals,
  nextStatuses,
  retrieveModalId,
  statusOptions,
} from './data/statusData';
import { rowConfig, tableHeaders } from './data/tableData';
import { tableFilters } from './data/tableFilters';
import { GET_DOG_LIABILITY_POLICIES } from './graphql/policies';
import { GET_DOG_LIABILITY_POLICY } from './graphql/singlePolicy';
import { UPDATE_DOG_LIABILITY_QUESTIONNAIRE } from './graphql/updateQuestionnaire';
import { UPDATE_DOG_LIABILITY_POLICY_STATUS } from './graphql/updateStatus';
import { DogLiabilityPolicyData } from './models/DogLiabilityPolicy';

const insurancePath = 'dog-liability';
const insuranceType = getDbInsuranceType(
  insurancePath
) as TemplateInsuranceType;

export const getDogLiabilityPoliciesTemplateData =
  (): PolicyTemplateData<DogLiabilityPolicyData> => {
    return {
      insuranceType,
      navigation: {
        current: `policies-${insurancePath}`,
      },
      queries: {
        allPolicies: GET_DOG_LIABILITY_POLICIES,
        policy: GET_DOG_LIABILITY_POLICY,
      },
      mutations: {
        statusChange: UPDATE_DOG_LIABILITY_POLICY_STATUS,
        editQuestionnaire: UPDATE_DOG_LIABILITY_QUESTIONNAIRE,
      },
      copy: {
        table: {
          title: `Dog liability policies`,
          searchPlaceholder:
            'Search by name, email, policy number, policy id or utm source',
        },
        details: {
          pageTitle: `Dog liability policy - Feather Admin Panel`,
          title: 'Dog liability',
        },
      },
      features: {
        overviewTable: {
          numberOfRowsPerPage: NUMBER_OF_POLICIES_PER_PAGE,
          headersConfig: tableHeaders,
          filtersConfig: tableFilters,
          rowConfig,
        },
        details: {
          queryVariables: {
            insuranceType,
          },
          insuredPersonName: (data?: DogLiabilityPolicyData) =>
            capitalizeName({
              firstName:
                data?.dogLiabilityPolicy.insuredPerson?.firstName ?? '',
              lastName: data?.dogLiabilityPolicy.insuredPerson?.lastName ?? '',
            }) ?? '',
          overviewTab: {
            getCards: getOverviewTabCards,
          },
        },
        statusChange: {
          statusOptions,
          statusChangeOptions: nextStatuses,
          getModals,
          retrieveModalId,
          extendedValidatorObject,
          additionalQueryVariables: { insuranceType },
        },
        documents: {
          uploadOptions: policyDocumentCategories,
          getDocumentSections: (data?: DogLiabilityPolicyData) =>
            getDocumentSections(data?.dogLiabilityPolicy),
        },
        questionnaire: {
          cardTitle: 'Personal information',
          editable: true,
          getQuestionnaireData,
        },
      },
    };
  };
