import Card from 'components/Card';
import dayjs from 'dayjs';
import { ClaimAssessment } from 'models/ClaimAssessment';

export const NoteForCustomer = ({
  claimAssessment,
}: {
  claimAssessment: ClaimAssessment;
}) => {
  const { notes = [] } = claimAssessment;

  const mostRecentNote = [...notes].sort((a, b) =>
    dayjs(a.createdAt).isAfter(b.createdAt) ? -1 : 1
  )[0];

  const hasNote = mostRecentNote && mostRecentNote.title;

  return (
    <Card title="Note for customer" boldTitle={false}>
      <div className="px-[24px] pb-3">
        {hasNote ? (
          <>
            {mostRecentNote.title && (
              <p className="text-sm">{mostRecentNote.title}</p>
            )}
            {mostRecentNote.description && (
              <p className="mt-2 text-sm text-gray-500">
                {mostRecentNote.description}
              </p>
            )}
          </>
        ) : (
          <>
            <p className="text-sm">No notes</p>
            <p className="mt-2 text-sm text-gray-500">
              You can add a note when updating the status
            </p>
          </>
        )}
      </div>
    </Card>
  );
};
