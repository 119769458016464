import { TFunction } from '@getpopsure/i18n-react';
import Button from 'components/Button';
import { HRTemplateInsuranceType } from 'pages/policies/template/models/InsuranceTypes';
import { PolicyTemplateData } from 'pages/policies/template/models/PolicyTemplateData';
import { getDbHRInsuranceType } from 'pages/policies/template/utils';

import { getDocumentSections } from './data/documents';
import { getOverviewTabCards } from './data/overviewTabCards';
import { getTableFilters } from './data/tableFilters';
import { getTableHeaders } from './data/tableHeaders';
import { getRowConfig } from './data/tableRows';
import { GET_HR_COMPANY_HEALTH_POLICIES } from './graphql/policies';
import { GET_HR_COMPANY_HEALTH_POLICY } from './graphql/policy';
import { CompanyHealthPolicy } from './models/CompanyHealthPolicy';

const insurancePath = 'company-health';
const insuranceType = getDbHRInsuranceType(
  insurancePath
) as HRTemplateInsuranceType;

export const getCompanyHealthPoliciesHRTemplateData = (
  t: TFunction,
  company?: string
): PolicyTemplateData<{ hrCompanyHealthPolicy: CompanyHealthPolicy }> => {
  return {
    insuranceType,
    navigation: {
      current: 'policies-company-health',
    },
    queries: {
      allPolicies: GET_HR_COMPANY_HEALTH_POLICIES,
      policy: GET_HR_COMPANY_HEALTH_POLICY,
    },
    mutations: {},
    copy: {
      table: {
        title: t('admin.hr.companyHealth.table.title', 'Company health'),
        searchPlaceholder: t(
          'admin.hr.companyHealth.table.search.placeholder',
          'Search by name, email or insurance number'
        ),
      },
      details: {
        pageTitle: t(
          'admin.hr.companyHealth.window.title',
          'Company health policy - Feather Admin Panel'
        ),
        title: t('admin.hr.companyHealth.table.title', 'Company health'),
      },
    },
    features: {
      languageSelector: true,
      overviewTable: {
        numberOfRowsPerPage: 12,
        headersConfig: getTableHeaders(t),
        filtersConfig: getTableFilters(t),
        rowConfig: getRowConfig(t, company ?? ''),
        queryVariables: {
          company,
        },
        defaultEmptyStateMessage: {
          title: t(
            'admin.hr.companyHealth.table.noPolicies.title',
            'Company health insurance is not activated yet'
          ),
          description: (
            <div className="flex max-w-md flex-col items-center space-y-4">
              <p className="text-center">
                {t(
                  'admin.hr.companyHealth.table.noPolicies.description1.label',
                  "It looks like your company hasn't set up a company health plan yet."
                )}
              </p>
              <p className="text-center">
                {t(
                  'admin.hr.companyHealth.table.noPolicies.description2.label',
                  'Company health insurance provides employees with a yearly budget to supplement their existing health coverage.'
                )}
              </p>
              <Button type="button" buttonType="secondary">
                <a href="https://feather-insurance.com/business/health-insurance">
                  {t(
                    'admin.hr.companyHealth.table.noPolicies.action.learnMore.label',
                    'Learn more'
                  )}
                </a>
              </Button>
            </div>
          ),
        },
      },
      details: {
        insuredPersonName: (data?: {
          hrCompanyHealthPolicy: CompanyHealthPolicy;
        }) =>
          `${data?.hrCompanyHealthPolicy.user?.firstName ?? ''} ${
            data?.hrCompanyHealthPolicy.user?.lastName ?? ''
          }`,
        overviewTab: {
          getCards: getOverviewTabCards(t),
        },
      },
      documents: {
        uploadOptions: [],
        getDocumentSections: getDocumentSections(t),
      },
    },
  };
};
