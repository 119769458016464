import Button from 'components/Button';
import { CreateClaimAssessmentModal } from 'pages/claimAssessments/template/components/CreateClaimAssessmentModal';
import { ClaimAssessmentsTemplateData } from 'pages/claimAssessments/template/models';
import { GET_SCANNED_DOCUMENT_BY_ID } from 'pages/scannedDocuments/scannedDocuments.queries';
import { ScannedDocument } from 'pages/scannedDocuments/scannedDocuments.types';
import { useState } from 'react';

export const CreateAPV = ({
  scannedDocument,
  claimAssessmentsTemplateData,
}: {
  scannedDocument: ScannedDocument;
  claimAssessmentsTemplateData: ClaimAssessmentsTemplateData;
}) => {
  const {
    queries: { fetchAllClaimAssessments, fetchAllPolicies },
    mutations: { createClaimAssessment },
    claimAssessmentCreation,
  } = claimAssessmentsTemplateData;

  const [showModal, setShowModal] = useState(false);

  if (!claimAssessmentsTemplateData) {
    return null;
  }

  return (
    <>
      {claimAssessmentCreation &&
        createClaimAssessment &&
        (showModal ? (
          <CreateClaimAssessmentModal
            setIsOpen={setShowModal}
            isOpen={showModal}
            claimAssessmentCreation={claimAssessmentCreation}
            createClaimAssessmentMutation={createClaimAssessment}
            fetchPoliciesQuery={fetchAllPolicies}
            refetchQueries={[
              fetchAllClaimAssessments,
              GET_SCANNED_DOCUMENT_BY_ID,
            ]}
            scannedDocumentToConnect={scannedDocument}
          />
        ) : (
          <></>
        ))}
      <Button
        buttonType="secondary"
        onClick={() => setShowModal(true)}
        loading={false}
      >
        Create
      </Button>
    </>
  );
};
