import { ExpatClaimStatus } from './models';

export const statusNameMapper: Record<ExpatClaimStatus, string> = {
  RECEIVED: 'Received',
  SUBMITTED_TO_PROVIDER: 'Sent',
  APPROVED_WITH_OTHER_CLAIMS: 'Approved in bundle',
  DUPLICATE: 'Duplicate',
  INCOMPLETE: 'Missing info',
  CUSTOMER_PAID_OUT: 'Customer paid out',
  OTHER_PAID_OUT: 'Paid to 3rd party',
  DENIED_WITH_OTHER_CLAIMS: 'Denied in bundle',
  DENIED: 'Denied',
  FEATHER_PAID_OUT: 'Feather paid out',
  CLOSED: 'Closed',
  MISSING_INFO_RECEIVED: 'Missing info received',
  PROCESSED_WITH_OTHER_CLAIM: 'Processed in bundle',
  MISSING_PAYMENT_INFO: 'Missing payment info',
  DROPPED_OUT: 'Dropped out',
};
