import classNames from 'classnames';
import Button from 'components/Button';
import dayjs from 'dayjs';
import { DocumentNode } from 'graphql';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { DATE_FORMAT } from 'models/date';
import { IssueWithCorrectTypes } from 'models/issue';
import { PropsWithChildren, useState } from 'react';
import { IssueCategory, mapIssueCategories } from 'shared/mapIssueCategories';

import { ReOpenIssueModal } from '../ReOpenIssueModal';
import { ResolveIssueModal } from '../ResolveIssueModal';
import * as styles from './styles';

interface IssueCardProps {
  issue: IssueWithCorrectTypes;

  resolveIssueMutation: DocumentNode;
  reOpenIssueMutation: DocumentNode;
  refetchQueries: DocumentNode[];

  policyOrClaimId?: string;
}

// TODO: [KONG] Add translations
export const IssueCard = ({
  issue,
  children,
  resolveIssueMutation,
  reOpenIssueMutation,
  refetchQueries,
  policyOrClaimId,
}: PropsWithChildren<IssueCardProps>) => {
  const [openResolveIssueModal, setOpenResolveIssueModal] = useState(false);
  const [openReOpenIssueModal, setOpenReOpenIssueModal] = useState(false);
  const [openActions, setOpenActions] = useState(false);

  const ref = useOutsideClick(() => {
    setOpenActions(false);
  });

  const isIssueOpen = issue?.status === 'OPEN';
  const isIssueExpiredWithCompletedTasks =
    issue?.status === 'EXPIRED' &&
    !!issue?.tasks?.length &&
    issue.tasks.every((task) => task.status === 'COMPLETED');
  const isCustomerContacted = issue?.status === 'CUSTOMER_CONTACTED';

  return (
    <>
      {openResolveIssueModal && (
        <ResolveIssueModal
          isOpen={openResolveIssueModal}
          setIsOpen={setOpenResolveIssueModal}
          resolveIssueMutation={resolveIssueMutation}
          issueId={issue.id}
          refetchQueries={refetchQueries}
          policyOrClaimId={policyOrClaimId}
        />
      )}
      {openReOpenIssueModal && (
        <ReOpenIssueModal
          isOpen={openReOpenIssueModal}
          setIsOpen={setOpenReOpenIssueModal}
          reOpenIssueMutation={reOpenIssueMutation}
          issueId={issue.id}
          refetchQueries={refetchQueries}
          policyOrClaimId={policyOrClaimId}
        />
      )}
      <div className={classNames(styles.issueCardWrapper)}>
        <header className={styles.header}>
          <h3 className={styles.category}>
            {issue?.title ??
              mapIssueCategories(issue?.category as IssueCategory)}
          </h3>
          <div className={styles.headerActions}>
            {/* Open and expired with all tasks completed issue action button */}
            {(isIssueOpen || isIssueExpiredWithCompletedTasks) && (
              <Button
                buttonType="primary"
                onClick={() => {
                  setOpenResolveIssueModal(true);
                }}
              >
                Resolve
              </Button>
            )}

            {/* Resolved issue action button */}
            {issue?.status === 'RESOLVED' && (
              <Button
                buttonType="secondary"
                onClick={() => {
                  setOpenReOpenIssueModal(true);
                }}
              >
                Re-open issue
              </Button>
            )}

            {/* Customer contacted */}
            {isCustomerContacted && (
              <div className={styles.waitingForCustomer}>
                Waiting for customer
              </div>
            )}

            <button
              className={styles.actionButton}
              type="button"
              onClick={() => {
                setOpenActions(!openActions);
              }}
              ref={ref}
            >
              <div className={styles.actionButtonDot} />
              <div className={styles.actionButtonDot} />
              <div className={styles.actionButtonDot} />
            </button>

            {openActions && (
              <div className={styles.actionsDropdown}>
                {(isIssueOpen || isCustomerContacted) && (
                  <button
                    className={styles.actionsDropdownButton}
                    type="button"
                    onClick={() => {
                      setOpenResolveIssueModal(true);
                    }}
                  >
                    Resolve
                  </button>
                )}

                {/* TODO: [KONG] Add view activity button */}
                {(isIssueOpen || isCustomerContacted) && (
                  <div className={styles.topBorder} />
                )}
                {issue.updatedAt && (
                  <div className={styles.actionsDropdownText}>
                    Updated on {dayjs(issue.updatedAt).format(DATE_FORMAT)}
                  </div>
                )}
                <div className={styles.actionsDropdownText}>
                  Created on {dayjs(issue.createdAt).format(DATE_FORMAT)}
                </div>
                <div className={styles.actionsDropdownText}>
                  Created by{' '}
                  {`${issue.author?.firstName} ${issue.author?.lastName}`}
                </div>
              </div>
            )}
          </div>
        </header>
        {issue?.description && (
          <p className={styles.description}>{issue?.description}</p>
        )}
        {children}
      </div>
    </>
  );
};
