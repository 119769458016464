import { SelectButtonOption } from 'components/SelectButton';
import { FilterConfig } from 'components/TableRefactor/Filters/types';
import {
  claimTypes,
  PrivateHealthClaimStatus,
  privateHealthClaimStatuses,
  PrivateHealthClaimType,
} from 'shared/claims/privateHealth/models';
import { policyMappers } from 'shared/insurances';

const statusOptions: SelectButtonOption<PrivateHealthClaimStatus>[] =
  privateHealthClaimStatuses.map((status) => ({
    id: status,
    label: policyMappers.PRIVATE_HEALTH?.claim?.claimStatusMapper[status] ?? '',
    title: policyMappers.PRIVATE_HEALTH?.claim?.claimStatusMapper[status] ?? '',
  }));

const claimTypeOptions: SelectButtonOption<PrivateHealthClaimType>[] =
  claimTypes.map((type) => ({
    id: type,
    label: policyMappers.PRIVATE_HEALTH?.claim?.typeNameMapper[type] ?? '',
    title: policyMappers.PRIVATE_HEALTH?.claim?.typeNameMapper[type] ?? '',
  }));
const claimAssessmentOptions: SelectButtonOption[] = [
  { id: 'any', label: 'Any' },
  { id: 'LINKED_TO_APV', label: 'Linked to APV' },
  { id: 'NOT_LINKED_TO_APV', label: 'Not linked to APV' },
];

export const filters: FilterConfig[] = [
  {
    options: statusOptions,
    filterType: 'MULTIPLE',
    label: 'Status',
    id: 'filterStatus',
    placeholder: 'Status',
  },
  {
    options: claimTypeOptions,
    filterType: 'MULTIPLE',
    label: 'Claim type',
    id: 'filterClaimType',
    placeholder: 'Claim type',
  },
  {
    options: claimAssessmentOptions,
    filterType: 'TEXT',
    label: 'Linked to APV',
    id: 'filterClaimAssessment',
    placeholder: 'Linked to APV',
  },
  {
    filterType: 'DATE_RANGE',
    label: 'Created on',
    id: 'createdAt',
  },
];
