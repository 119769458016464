import { gql } from '@apollo/client';

export const GET_CLAIM_ASSESSMENT = gql`
  query GetClaimAssessment($claimAssessmentId: String!) {
    claimAssessment(claimAssessmentId: $claimAssessmentId) {
      id
      status
      createdAt
      user {
        id
        firstName
        lastName
      }
      policy {
        id
        policyNumber
        insuranceType
        providerId
      }
      title
      diagnosis
      doctorName
      doctorEmail
      claims {
        id
        status
        amount
        claimType {
          id
          type
        }
        createdAt
      }
      notes {
        createdAt
        title
        description
      }
      documents {
        userUploads {
          id
          url
          blobName
          resizedUrl
        }
        scannedDocuments {
          id
          url
          blobName
        }
        featherUploads {
          id
          url
          blobName
          resizedUrl
          isVisibleToCustomer
          internalNotes
          friendlyName
          category
        }
        providerUploads {
          id
          url
          blobName
          resizedUrl
          isVisibleToCustomer
          internalNotes
          friendlyName
          category
        }
        archivedDocuments {
          id
          url
          blobName
          resizedUrl
          isVisibleToCustomer
          archivedAt
          internalNotes
          friendlyName
          category
        }
      }
    }
  }
`;

export const GET_CLAIMS_BY_POLICY_ID = gql`
  query GetClaimsByPolicyId($userPolicyId: String!, $searchString: String!) {
    claimsByPolicyId(userPolicyId: $userPolicyId, searchString: $searchString) {
      id
      status
      amount
      claimNumber
      claimType {
        id
        createdAt
        type
      }
    }
  }
`;
