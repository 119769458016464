import { Address } from '@popsure/public-models';
import { ClaimAssessmentStatus } from 'shared/claimAssessments/models';
import {
  LegalExpectedOutcomeType,
  LegalPreferredTimeDateType,
} from 'shared/claims/legal/models';
import { CombinedClaimType } from 'shared/claims/models';

import { Document } from './Document';
import { IssueWithCorrectTypes } from './issue';
import { Note } from './Note';
import { User } from './User';

export type ClaimThirdPartyAddress =
  | Omit<Address, 'additionalInformation' | 'houseNumber'> & {
      additional_information: string;
      house_number: string;
    };

export interface Claim<Policy, Status> {
  id: string;
  intercomEuUserId: string | null;
  stripeCustomerToken: string | null;
  user: User;
  status: Status | null;
  amount: number | null;
  createdAt: string;
  claimType: StoredClaimTypeObject[] | null;
  userClaimAssessment: { id: string; status: ClaimAssessmentStatus } | null;
  policy: Policy;
  documents: ClaimDocuments;
  refundAmount: number | null;
  featherPaidOn: string | null;
  customerPaidOn: string | null;
  thirdPartyPaidOn: string | null;
  claimNumber: string | null;
  provision: number | null;
  otherCosts: number | null;
  dateOfEvent: string | null;
  eventDescription: string | null;
  thirdParty: {
    name: string | null;
    email: string;
    address: ClaimThirdPartyAddress;
  } | null;
  submissions: ClaimSubmission[];
  notificationSentToProviderAt: string | null;

  // Payment
  accountHoldersName: string | null;
  iban: string | null;
  bic: string | null;

  contacts: string | null;

  expectedOutcome: LegalExpectedOutcomeType | null;

  preferredTimeDate: LegalPreferredTimeDateType | null;

  issues: IssueWithCorrectTypes[];

  pinnedNotes: Note[];

  regionOfPurchase?: string | null;
}

export interface StoredClaimTypeObject {
  id: string;
  type: CombinedClaimType;
}

export interface ClaimDocuments {
  userUploads: Document[];
  scannedDocuments: Document[];
  featherUploads: Document[];
  providerUploads: Document[];
  archivedDocuments: Document[];
}

export const claimFileTypes = [
  'FEATHER_UPLOADS',
  'CUSTOMER_UPLOADS',
  'PROVIDER_UPLOADS',
  'SCANNED_DOCUMENT',
  'ARCHIVED',
] as const;
export type ClaimFileType = typeof claimFileTypes[number];

export const claimFileVisibilityTargets = ['CUSTOMER'] as const;
export type ClaimFileVisibilityTarget =
  typeof claimFileVisibilityTargets[number];

export interface ClaimSubmission {
  id: string;
  createdAt: string;
  submissionDetails: Record<any, any> | null;
  errorDetails: Record<any, any> | null;
}
