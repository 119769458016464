import ComboBoxInformation from 'components/InformationCard/ComboBoxInformation';
import { CurrencyInformation } from 'components/InformationCard/CurrencyInformation';
import DateInformation from 'components/InformationCard/DateInformation';
import DropdownInformation from 'components/InformationCard/DropdownInformation';
import LinkInformation from 'components/InformationCard/LinkInformation';
import StatusInformation from 'components/InformationCard/StatusInformation';
import TextBoxInformation from 'components/InformationCard/TextBoxInformation';
import TextInformation from 'components/InformationCard/TextInformation';
import TextWithDetailsInformation from 'components/InformationCard/TextWithDetailsInformation';
import { ReferralCode } from 'components/ReferralCode/ReferralCode';
import { Status } from 'models/statusColor';
import { InsurancePathName } from 'pages/claims/template/models';
import {
  isInsurancePathName,
  retrieveClaimsOverviewPath,
} from 'pages/claims/template/utils/utils';
import ChangeAccount from 'pages/policies/template/ChangeAccount/ChangeAccount';
import { PolicyTemplateInsurancePath } from 'pages/policies/template/models/InsuranceTypes';
import {
  InformationCardDetails,
  InformationCardEditableDetails,
} from 'pages/policies/template/models/PolicyTemplateData';
import { UPDATE_POLICY_CANCELATION_REQUEST } from 'pages/policies/template/mutations';
import { policyMappers } from 'shared/insurances';
import { setQueryToUrl } from 'shared/utils/setQueryToUrl';

import { GET_GENERIC_POLICY } from '../graphql/policy';
import { UPDATE_GENERIC_POLICY } from '../graphql/updatePolicy';
import { GenericPolicyData } from '../models/GenericPolicyData';
import { providerOptions } from './createPolicyFormData';

const statusMapper = (status: string): Status => {
  const policyMapper = policyMappers.GENERIC;
  return {
    text: {
      id: status,
      text: policyMapper?.statusMapper[status] ?? '',
    },
    color: policyMapper?.badgeColorMapper[status] ?? 'gray',
  };
};

export const getOverviewTabCards = (
  insurancePath: PolicyTemplateInsurancePath | InsurancePathName,
  data?: GenericPolicyData,
  extraData?: any[],
  setSearchQuery?: (query: string) => void
): (
  | InformationCardDetails
  | InformationCardEditableDetails<GenericPolicyData>
)[] => {
  const customerInformation: InformationCardDetails = {
    editable: false,
    cards: [
      {
        title: 'Customer information',
        rows: [
          {
            id: 'firstName',
            title: 'First name',
            data:
              data?.genericPolicy.insuredPerson?.firstName ??
              data?.genericPolicy?.user?.firstName ??
              '',
            component: TextInformation,
            type: 'TEXT',
          },
          {
            id: 'lastName',
            title: 'Last name',
            data:
              data?.genericPolicy.insuredPerson?.lastName ??
              data?.genericPolicy?.user?.lastName ??
              '',
            component: TextInformation,
            type: 'TEXT',
          },
          {
            id: 'email',
            title: 'Email',
            data: data?.genericPolicy?.user?.email ?? '',
            component: TextWithDetailsInformation,
            type: 'TEXT_WITH_DETAILS',
            detailsTitle: 'Change account',
            detailsLabel: 'Change account',
            renderModalDetails: (setOpen, modalOpen) => (
              <ChangeAccount
                policyDetailsMutation={GET_GENERIC_POLICY}
                setOpen={setOpen}
                modalOpen={modalOpen}
              />
            ),
          },
        ],
      },
    ],
  };

  const policyInformation: InformationCardEditableDetails<GenericPolicyData> = {
    editable: true,
    mutation: UPDATE_GENERIC_POLICY,
    resourceId: data?.genericPolicy.id ?? '',
    editabledData: {
      policyNumber: data?.genericPolicy.policyNumber ?? '',
      providerPolicyNumber: data?.genericPolicy.providerPolicyNumber ?? '',
      insuranceCardNumber: data?.genericPolicy.insuranceCardNumber ?? '',
      providerId: data?.genericPolicy.providerId ?? '',
      price: data?.genericPolicy.price ?? 0,
      startDate: data?.genericPolicy.startDate ?? '',
      formId: data?.genericPolicy.formId ?? '',
      policyPurchaseEmail: data?.genericPolicy.policyPurchaseEmail ?? '',
      source: data?.genericPolicy.source ?? '',
      campaign: data?.genericPolicy.campaign ?? '',
      medium: data?.genericPolicy.medium ?? '',
      content: data?.genericPolicy.content ?? '',
      term: data?.genericPolicy.term ?? '',
      genericPolicyData:
        JSON.stringify(data?.genericPolicy.genericPolicyData) ?? '',
      companyId: data?.genericPolicy.company?.id ?? '',
      customerSalary: data?.genericPolicy.customerSalary,
    },
    cards: [
      {
        title: 'Policy information',
        rows: [
          {
            id: 'policyNumber',
            title: 'Policy number',
            data: data?.genericPolicy?.policyNumber ?? '',
            component: TextInformation,
            type: 'TEXT',
            editable: true,
            placeholder: 'Enter policy number',
          },
          {
            id: 'providerPolicyNumber',
            title: 'Provider policy number',
            data: data?.genericPolicy?.providerPolicyNumber ?? '',
            component: TextInformation,
            type: 'TEXT',
            editable: true,
            placeholder: 'Enter provider policy number',
          },
          {
            id: 'status',
            title: 'Status',
            data: data?.genericPolicy?.publicStatus ?? '',
            type: 'STATUS',
            component: StatusInformation,
            statusMapping: statusMapper,
          },
          {
            id: 'providerId',
            title: 'Provider',
            data: data?.genericPolicy?.providerId ?? '',
            type: 'DROPDOWN',
            component: DropdownInformation,
            editable: true,
            dropdownOptions: providerOptions,
            optionMapping: (id: string) =>
              providerOptions.find((option) => option.id === id)?.label,
            multipleOptions: false,
            placeholder: 'Select provider',
          },
          {
            id: 'genericPolicyData',
            title: 'Policy data',
            data: data?.genericPolicy?.genericPolicyData
              ? JSON.stringify(data?.genericPolicy?.genericPolicyData)
              : '',
            component: TextBoxInformation,
            type: 'TEXTBOX',
            editable: true,
          },
          {
            id: 'insuranceCardNumber',
            title: 'Insurance card number',
            data: data?.genericPolicy?.insuranceCardNumber ?? '',
            component: TextInformation,
            type: 'TEXT',
            editable: true,
            placeholder: 'Enter insurance card number',
          },
          {
            id: 'price',
            title: 'Price',
            data: data?.genericPolicy?.price ?? 0,
            component: CurrencyInformation,
            type: 'CURRENCY',
            editable: true,
            placeholder: '€ 0.00',
          },
          {
            id: 'startDate',
            title: 'Start date',
            data: data?.genericPolicy?.startDate ?? '',
            component: DateInformation,
            type: 'DATE',
            editable: true,
          },
          ...(data?.genericPolicy.numberOfClaims !== 0 &&
          // We need to narrow down the type from the general PolicyTemplateInsurancePath
          // to the specific InsurancePathName that the claims feature uses
          isInsurancePathName(insurancePath)
            ? [
                {
                  id: 'claims',
                  title: 'Claims',
                  data: `${data?.genericPolicy.numberOfClaims} claims`,
                  href: setQueryToUrl(
                    retrieveClaimsOverviewPath(
                      insurancePath,
                      data?.genericPolicy.regionOfPurchase
                    ),
                    [
                      {
                        key: 'search',
                        value: data?.genericPolicy.policyNumber ?? '',
                      },
                    ]
                  ),
                  externalLink: true,
                  type: 'LINK' as const,
                  component: LinkInformation,
                },
              ]
            : []),
          {
            id: 'createdAt',
            title: 'Created on',
            data: data?.genericPolicy?.createdAt ?? '',
            component: DateInformation,
            type: 'DATE',
          },
          {
            id: 'formId',
            title: 'Form ID',
            data: data?.genericPolicy?.formId ?? '',
            component: TextInformation,
            type: 'TEXT',
            editable: true,
            placeholder: 'Enter form ID',
          },
          {
            id: 'policyPurchaseEmail',
            title: 'Policy purchase email',
            data: data?.genericPolicy?.policyPurchaseEmail ?? '',
            component: TextInformation,
            type: 'TEXT',
            editable: true,
            placeholder: 'Enter policy email',
          },
          {
            id: 'companyId',
            title: 'Company',
            data:
              extraData?.find(
                (company) => company.id === data?.genericPolicy.company?.id
              )?.id ?? '',
            type: 'DROPDOWN' as const,
            component: ComboBoxInformation,
            dropdownOptions:
              extraData?.map(({ id, name }) => ({
                id,
                label: name,
              })) ?? [],
            multipleOptions: false,
            placeholder: 'Select company',
            optionMapping: (companyId: string) =>
              extraData?.find((company) => company.id === companyId)?.name ??
              data?.genericPolicy.company?.name,
            editable: true,
            useExternalQuery: true,
            setExternalQuery: setSearchQuery,
            required: false,
          },
          {
            id: 'source',
            title: 'Source',
            data: data?.genericPolicy?.source ?? '',
            component: TextInformation,
            type: 'TEXT',
            editable: true,
            placeholder: 'Enter source',
          },
          {
            id: 'campaign',
            title: 'Campaign',
            data: data?.genericPolicy?.campaign ?? '',
            component: TextInformation,
            type: 'TEXT',
            editable: true,
            placeholder: 'Enter campaign',
          },
          {
            id: 'medium',
            title: 'Medium',
            data: data?.genericPolicy?.medium ?? '',
            component: TextInformation,
            type: 'TEXT',
            editable: true,
            placeholder: 'Enter medium',
          },
          {
            id: 'content',
            title: 'Content',
            data: data?.genericPolicy?.content ?? '',
            component: TextInformation,
            type: 'TEXT',
            editable: true,
            placeholder: 'Enter content',
          },
          {
            id: 'term',
            title: 'Term',
            data: data?.genericPolicy?.term ?? '',
            component: TextInformation,
            type: 'TEXT',
            editable: true,
            placeholder: 'Enter term',
          },
          {
            id: 'referralCode',
            title: 'Referral code',
            data: data?.genericPolicy.referral?.referralCode ?? '',
            type: data?.genericPolicy.referral?.status
              ? 'TEXT_WITH_DETAILS'
              : 'TEXT',
            detailsTitle: 'Add referral code',
            detailsLabel: 'Add referral code',
            component: TextWithDetailsInformation,
            renderModalDetails: (setOpen, modalOpen) => (
              <ReferralCode
                policyId={data?.genericPolicy?.id ?? ''}
                userId={data?.genericPolicy?.user?.id ?? ''}
                setOpen={setOpen}
                modalOpen={modalOpen}
              />
            ),
          },
          ...(data?.genericPolicy.insuranceType === 'COMPANY_LIFE'
            ? [
                {
                  id: 'customerSalary',
                  title: 'Salary',
                  data: data?.genericPolicy.customerSalary,
                  component: CurrencyInformation,
                  type: 'CURRENCY' as const,
                  editable: true,
                  placeholder: '€ 0.00',
                },
              ]
            : []),
        ],
      },
    ],
  };

  const policyCancellationInformation: InformationCardEditableDetails<GenericPolicyData> =
    {
      editable: true,
      mutation: UPDATE_POLICY_CANCELATION_REQUEST,
      resourceId: data?.genericPolicy?.policyCancelationRequest?.id ?? '',
      editabledData: {
        activeUntil: data?.genericPolicy?.activeUntil ?? '',
        createdAt:
          data?.genericPolicy?.policyCancelationRequest?.createdAt ?? '',
        canceledOnStripeAt:
          data?.genericPolicy?.policyCancelationRequest?.canceledOnStripeAt ??
          '',
        requestSentToProviderAt:
          data?.genericPolicy?.policyCancelationRequest
            ?.requestSentToProviderAt ?? '',
        cancelationConfirmedByProviderAt:
          data?.genericPolicy?.policyCancelationRequest
            ?.cancelationConfirmedByProviderAt ?? '',
      },
      extraVariables: {
        insuranceType: data?.genericPolicy.insuranceType,
        policyId: data?.genericPolicy?.id ?? '',
      },
      cards: [
        {
          title: 'Policy cancellation',
          rows: [
            {
              id: 'activeUntil',
              title: 'Active until',
              data: data?.genericPolicy?.activeUntil ?? '',
              component: DateInformation,
              type: 'DATE',
              editable: true,
            },
            {
              id: 'createdAt',
              title: 'Requested by customer on',
              data:
                data?.genericPolicy?.policyCancelationRequest?.createdAt ?? '',
              component: DateInformation,
              type: 'DATE',
              editable: true,
            },
            {
              id: 'canceledOnStripeAt',
              title: 'Stripe canceled on',
              data:
                data?.genericPolicy?.policyCancelationRequest
                  ?.canceledOnStripeAt ?? '',
              component: DateInformation,
              type: 'DATE',
              editable: true,
            },
            {
              id: 'requestSentToProviderAt',
              title: 'Request sent to provider on',
              data:
                data?.genericPolicy?.policyCancelationRequest
                  ?.requestSentToProviderAt ?? '',
              component: DateInformation,
              type: 'DATE',
              editable: true,
            },
            {
              id: 'cancelationConfirmedByProviderAt',
              title: 'Provider confirmed on',
              data:
                data?.genericPolicy?.policyCancelationRequest
                  ?.cancelationConfirmedByProviderAt ?? '',
              component: DateInformation,
              type: 'DATE',
              editable: true,
            },
          ],
        },
      ],
    };

  return [
    customerInformation,
    policyInformation,
    ...(data?.genericPolicy.publicStatus === 'CANCELED' ||
    data?.genericPolicy.publicStatus === 'DROPPED_OUT'
      ? [policyCancellationInformation]
      : []),
  ];
};
