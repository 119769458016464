import { gql } from '@apollo/client';

import { PRIVATE_DOCUMENT_FIELDS } from './documents';
import { POLICY_ACTIVITY } from './policyActivity';
import { PRIVATE_QUESTIONNAIRE_FIELDS } from './questionnaire';
import { TARIFF_INFO } from './tariffInfo';

export const GET_PRIVATE_POLICIES = gql`
  query GetPrivateHealthPolicies(
    $offset: Float!
    $limit: Float!
    $searchString: String!
    $sortColumn: String!
    $sortOrder: String!
    $filterStatus: [String]!
    $filterOccupation: [String]!
    $filterView: String
    $applicationSent: String!
    $createdAtRangeStart: DateTime
    $createdAtRangeEnd: DateTime
  ) {
    privateHealthPolicies(
      offset: $offset
      limit: $limit
      searchString: $searchString
      sortColumn: $sortColumn
      sortOrder: $sortOrder
      filterStatus: $filterStatus
      filterOccupation: $filterOccupation
      filterView: $filterView
      applicationSent: $applicationSent
      createdAtRangeStart: $createdAtRangeStart
      createdAtRangeEnd: $createdAtRangeEnd
    ) {
      id
      createdAt
      user {
        id
        firstName
        lastName
        isDelinquent
        isFraudulent
        fraudulentAdditionalInfo
      }
      publicStatus
      startDate
      tariff
      archivedAt
      insuredPerson {
        occupation
        firstName
        lastName
      }
      applicationSentAt
      currentTariffInfo {
        tariff
      }
      policyNumber
      source

      issues {
        id
        status
        tasks {
          id
          status
        }
      }
    }
    privateHealthPoliciesCount(
      searchString: $searchString
      filterStatus: $filterStatus
      filterOccupation: $filterOccupation
      filterView: $filterView
      applicationSent: $applicationSent
      createdAtRangeStart: $createdAtRangeStart
      createdAtRangeEnd: $createdAtRangeEnd
    ) {
      totalPolicies
      totalOpenIssuesPolicies
      totalTaskReviewNeededPolicies
    }
  }
`;

export const GET_PRIVATE_POLICY = gql`
  ${PRIVATE_QUESTIONNAIRE_FIELDS}
  ${PRIVATE_DOCUMENT_FIELDS}
  ${TARIFF_INFO}
  ${POLICY_ACTIVITY}
  query GetPrivateHealthPolicy($id: String!) {
    privateHealthPolicy(id: $id) {
      id
      user {
        id
        email
        firstName
        lastName
        intercomEUId
        stripeToken
        dateOfBirth
        gender
        isDelinquent
        isFraudulent
        fraudulentAdditionalInfo
      }
      insuredPerson {
        occupation
        firstName
        lastName
        dateOfBirth
        gender
        email
      }
      publicStatus
      policyNumber
      providerId
      startDate
      applicationSentAt
      endDate
      numberOfClaims
      createdAt
      archivedAt
      source
      campaign
      medium
      content
      term
      iban
      confirmationOfCoverageFromProviderUrl
      questionnaire {
        answers {
          ...PrivateQuestionnaireAnswerFields
        }
        id
      }
      ...PrivateDocumentFields
      currentTariffInfo {
        ...TariffInfoFields
      }
      nextActiveTariffInfo {
        ...TariffInfoFields
      }
      ...PolicyActivity
      openIssues {
        id
        createdAt
        author {
          email
          company
          firstName
          lastName
        }
        description
        category
        status
      }
      scannedDocuments {
        id
        blobName
        url
        friendlyName
        visibleInUserAccount
      }
      newRelatedScannedDocumentsCount
      totalRelatedScannedDocumentsCount

      privateHealthPaymentMethod

      issues {
        id
        category
        description
        status
        title
        createdAt
        updatedAt
        author {
          firstName
          lastName
        }

        tasks {
          id
          description
          status
          data
          createdAt
          finishedAt
        }
      }

      pinnedNotes {
        id
        body
        createdAt
        adminUser {
          firstName
          lastName
        }
        updatedAt
      }

      referral {
        referralCode
        status
      }

      company {
        id
        name
      }
    }
  }
`;

export const GET_TARIFF_INFOS_BY_SIMILAR_POLICY_NUMBERS = gql`
  query findTariffInfosBySimilarPolicyNumber($policyNumbers: [String]!) {
    findTariffInfosBySimilarPolicyNumber(policyNumbers: $policyNumbers) {
      tariffInfo {
        id
        startDate
        endDate
        tariff
        deductible
        riskLevel
        addOns
        longTermCare
        sickDayPayout
        totalMonthlyPrice
        statutorySurcharge
        tariffMonthlyPrice
        riskFactorSurcharge
        employerContributions
        ownContributions
        noClaimsBonus
        guaranteedYearlyBonus
        taxDeductibleAmount
        userPolicyId
      }
      policy {
        id
        policyNumber
        insuredPerson {
          firstName
          lastName
        }
      }
    }
  }
`;
