import FileErrorCard from 'components/FileErrorCard';
import { ClaimAssessmentStatus } from 'shared/claimAssessments/models';

import { retrieveClaimAssessmentsDetailsPath } from '../../../../../claimAssessments/template/utils/utils';

export const ClaimUnderAPVInvestigationBanner = ({
  claim,
  loading = false,
}: {
  claim?: {
    id?: string;
    claimAssessment: {
      id: string;
      status: ClaimAssessmentStatus;
    } | null;
  };
  loading?: boolean;
}) => {
  const hasUnresolvedAPV =
    claim?.claimAssessment?.status &&
    !['POSITIVE', 'NEGATIVE'].includes(claim.claimAssessment.status);

  if (loading) return null;

  if (!loading && !hasUnresolvedAPV) return null;

  return (
    <FileErrorCard
      open
      hideCloseButton
      handleClose={() => {}}
      title="This claim is under APV investigation"
      description="Until the APV is resolved, the customer-facing status will show “Under investigation”."
      errorType="WARNING"
      className="!mt-0 mb-[16px]"
      showActionButtons={true}
      primaryButton={{
        text: 'See APV',
        onClick: () => {
          if (claim?.claimAssessment?.id) {
            window.location.href = retrieveClaimAssessmentsDetailsPath(
              claim.claimAssessment.id
            );
          }
        },
      }}
    />
  );
};
