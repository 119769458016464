import { TFunction } from '@getpopsure/i18n-react';
import Button from 'components/Button';
import { HRTemplateInsuranceType } from 'pages/policies/template/models/InsuranceTypes';
import { PolicyTemplateData } from 'pages/policies/template/models/PolicyTemplateData';
import { getDbHRInsuranceType } from 'pages/policies/template/utils';

import { getDocumentSections } from './data/documents';
import { getOverviewTabCards } from './data/overviewTabCards';
import { getTableFilters } from './data/tableFilters';
import { getTableHeaders } from './data/tableHeaders';
import { getRowConfig } from './data/tableRows';
import { GET_HR_COMPANY_LIFE_POLICIES } from './graphql/policies';
import { GET_HR_COMPANY_LIFE_POLICY } from './graphql/policy';
import { CompanyLifePolicy } from './models/CompanyLifePolicy';

const insurancePath = 'company-life';
const insuranceType = getDbHRInsuranceType(
  insurancePath
) as HRTemplateInsuranceType;

export const getCompanyLifePoliciesHRTemplateData = (
  t: TFunction,
  company?: string
): PolicyTemplateData<{ hrCompanyLifePolicy: CompanyLifePolicy }> => {
  return {
    insuranceType,
    navigation: {
      current: 'policies-company-life',
    },
    queries: {
      allPolicies: GET_HR_COMPANY_LIFE_POLICIES,
      policy: GET_HR_COMPANY_LIFE_POLICY,
    },
    mutations: {},
    copy: {
      table: {
        title: t('admin.hr.companyLife.table.title', 'Company life'),
        searchPlaceholder: t(
          'admin.hr.companyLife.table.search.placeholder',
          'Search by name, email or insurance number'
        ),
      },
      details: {
        pageTitle: t(
          'admin.hr.companyLife.window.title',
          'Company life policy - Feather Admin Panel'
        ),
        title: t('admin.hr.companyLife.table.title', 'Company life'),
      },
    },
    features: {
      languageSelector: true,
      overviewTable: {
        numberOfRowsPerPage: 12,
        headersConfig: getTableHeaders(t),
        filtersConfig: getTableFilters(t),
        rowConfig: getRowConfig(t, company ?? ''),
        queryVariables: {
          company,
        },
        defaultEmptyStateMessage: {
          title: t(
            'admin.hr.companyLife.table.noPolicies.title',
            'Company life insurance is not activated yet'
          ),
          description: (
            <div className="flex max-w-md flex-col items-center space-y-4">
              <p className="text-center">
                {t(
                  'admin.hr.companyLife.table.noPolicies.description1.label',
                  "It looks like your company hasn't set up a company life insurance yet. "
                )}
              </p>
              <p className="text-center">
                {t(
                  'admin.hr.companyLife.table.noPolicies.description2.label',
                  "Company life insurance provides a lump sum payment to the employee's beneficiaries to support them in the event of a loss."
                )}
              </p>
              <Button type="button" buttonType="secondary">
                <a href="https://feather-insurance.com/business/life-insurance">
                  {t(
                    'admin.hr.companyLife.table.noPolicies.action.learnMore.label',
                    'Learn more'
                  )}
                </a>
              </Button>
            </div>
          ),
        },
      },
      details: {
        insuredPersonName: (data?: {
          hrCompanyLifePolicy: CompanyLifePolicy;
        }) =>
          `${data?.hrCompanyLifePolicy.user?.firstName ?? ''} ${
            data?.hrCompanyLifePolicy.user?.lastName ?? ''
          }`,
        overviewTab: {
          getCards: getOverviewTabCards(t),
        },
      },
      documents: {
        uploadOptions: [],
        getDocumentSections: getDocumentSections(t),
      },
    },
  };
};
