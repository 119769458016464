import { gql } from '@apollo/client';

export const CREATE_INTERNAL_UPLOAD = gql`
  mutation CreateInternalUpload($documents: [InternalUploadInput!]!) {
    createInternalUpload(documents: $documents) {
      id
      blobName
      url
      createdAt
      friendlyName
      uploadType
      category
      isVisibleToCustomer
      isVisibleToHr
      isVisibleToProvider
      internalNotes
      companyId
      userClaimAssessmentId
    }
  }
`;

export const EDIT_INTERNAL_UPLOAD = gql`
  mutation EditInternalUpload(
    $id: String!
    $friendlyName: String
    $uploadType: String
    $category: String
    $isVisibleToProvider: Boolean
    $isVisibleToHr: Boolean
    $isVisibleToCustomer: Boolean
    $internalNotes: String
  ) {
    editInternalUpload(
      id: $id
      friendlyName: $friendlyName
      uploadType: $uploadType
      category: $category
      isVisibleToProvider: $isVisibleToProvider
      isVisibleToHr: $isVisibleToHr
      isVisibleToCustomer: $isVisibleToCustomer
      internalNotes: $internalNotes
    ) {
      id
      blobName
      url
      resizedUrl
      createdAt
      uploadType
      category
      friendlyName
      isVisibleToCustomer
      isVisibleToHr
      isVisibleToProvider
      archivedAt
      internalNotes
      userClaimAssessmentId
      companyId
    }
  }
`;

export const DELETE_INTERNAL_UPLOAD = gql`
  mutation DeleteInternalUpload($id: String!) {
    deleteInternalUpload(id: $id) {
      id
    }
  }
`;

export const ARCHIVE_INTERNAL_UPLOAD = gql`
  mutation ArchiveInternalUpload($id: String!) {
    archiveInternalUpload(id: $id) {
      archivedAt
      id
    }
  }
`;

export const RESTORE_INTERNAL_UPLOAD = gql`
  mutation RestoreInternalUpload($id: String!) {
    restoreInternalUpload(id: $id) {
      archivedAt
      id
    }
  }
`;
