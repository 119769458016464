import { useTranslation } from '@getpopsure/i18n-react';
import {
  DocumentSection,
  DocumentsSection,
} from 'components/DocumentsSection/DocumentsSection';
import {
  GET_COMPANY,
  GET_HR_COMPANY_BY_NAME,
} from 'graphql/admin/companies/queries';
import { InternalUploads } from 'shared/internalUploads/models';
import {
  CREATE_INTERNAL_UPLOAD,
  DELETE_INTERNAL_UPLOAD,
  EDIT_INTERNAL_UPLOAD,
} from 'shared/internalUploads/queries';
import { retrieveFileFriendlyName } from 'shared/utils/retrieveFileFriendlyName';

import { EditButton } from './components/EditButton';
import { UploadButton } from './components/UploadButton';

interface DocumentsSectionProps {
  companyId: string;
  documents: InternalUploads[];
  isHRView: boolean;
}

export const CompanyDocuments = ({
  companyId,
  documents,
  isHRView,
}: DocumentsSectionProps) => {
  const { t } = useTranslation();

  const formattedInternalUploads = documents?.map((document) => {
    const {
      friendlyName,
      blobName,
      uploadType,
      category,
      archivedAt,
      internalNotes,
    } = document;

    return {
      ...document,
      fileName: friendlyName ?? retrieveFileFriendlyName(blobName),
      friendlyName: friendlyName === null ? undefined : friendlyName,
      category: category === null ? undefined : category,
      uploadType: uploadType === null ? undefined : uploadType,
      archivedAt: archivedAt === null ? undefined : archivedAt,
      internalNotes: internalNotes === null ? undefined : internalNotes,
      canDelete: !isHRView,
      canEdit: !isHRView,
      canArchive: false,
    };
  });

  const companyDocuments: DocumentSection = {
    title: t('admin.companyDocuments.section.title', 'Company documents'),
    documents: formattedInternalUploads,
  };

  const selectMenuOptions = [{ id: 'HR', label: 'HR' }];

  return (
    <div className="w-full lg:ml-[20px] lg:w-auto">
      <DocumentsSection
        sections={companyDocuments.documents ? [companyDocuments] : []}
        resourceId={companyId}
        getResourceQuery={isHRView ? GET_HR_COMPANY_BY_NAME : GET_COMPANY}
        deleteMutation={isHRView ? undefined : DELETE_INTERNAL_UPLOAD}
        editMutation={isHRView ? undefined : EDIT_INTERNAL_UPLOAD}
        editButton={EditButton}
        documentVisibilityOptions={selectMenuOptions}
        documentCategories={selectMenuOptions}
        uploadButton={
          isHRView ? null : (
            <UploadButton
              resourceId={companyId}
              getResourceQuery={GET_COMPANY}
              documentVisibilityOptions={selectMenuOptions}
              displayInternalNotes={true}
              mutation={CREATE_INTERNAL_UPLOAD}
            />
          )
        }
      />
    </div>
  );
};
