import { InformationSection } from 'components/InformationCard';
import { ClaimAssessment } from 'models/ClaimAssessment';

import { ClaimAssessmentInfo } from '../ClaimAssessmentInfo';
import { Documents } from '../Documents';
import { LinkedClaimsInfo } from '../LinkedClaimsInfo/LinkedClaimsInfo';
import { NoteForCustomer } from '../NoteForCustomer/NoteForCustomer';
import * as styles from './styles';

interface Props {
  claimAssessmentInfo: ClaimAssessment;
  retrieveClaimAssessmentInfoRows: (
    claimAssessmentInfo: ClaimAssessment
  ) => InformationSection[];
}

export const OverviewTab = ({
  claimAssessmentInfo,
  retrieveClaimAssessmentInfoRows,
}: Props) => {
  const claimAssessmentInfoData =
    retrieveClaimAssessmentInfoRows(claimAssessmentInfo);

  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <ClaimAssessmentInfo
          claimAssessmentId={claimAssessmentInfo.id}
          claimAssessmentInfoData={claimAssessmentInfoData}
        />
        <LinkedClaimsInfo data={claimAssessmentInfo} />
      </div>
      <div className={styles.rightContainer}>
        <NoteForCustomer claimAssessment={claimAssessmentInfo} />
        <Documents
          documents={claimAssessmentInfo.documents}
          claimAssessmentId={claimAssessmentInfo.id}
          userId={claimAssessmentInfo.user.id}
          userPolicyId={claimAssessmentInfo.policy.id}
        />
      </div>
    </div>
  );
};
