import { gql } from '@apollo/client';

export const UPDATE_CLAIM_ASSESSMENT = gql`
  mutation UpdateClaimAssessment(
    $claimAssessmentId: String!
    $doctorName: String
    $doctorEmail: String
    $diagnosis: String
    $title: String
  ) {
    updateClaimAssessment(
      claimAssessmentId: $claimAssessmentId
      doctorName: $doctorName
      doctorEmail: $doctorEmail
      diagnosis: $diagnosis
      title: $title
    ) {
      id
      createdAt
      status
      title
      doctorName
      doctorEmail
      diagnosis
    }
  }
`;

export const UPDATE_CLAIM_ASSESSMENT_STATUS = gql`
  mutation UpdateClaimAssessmentStatus(
    $claimAssessmentId: String!
    $userPolicyId: String!
    $status: String!
    $noteForCustomer: Boolean!
    $noteTitle: String
    $noteDescription: String
    $uploadDocument: Upload
    $documentName: String
  ) {
    updateClaimAssessmentStatus(
      claimAssessmentId: $claimAssessmentId
      userPolicyId: $userPolicyId
      status: $status
      noteForCustomer: $noteForCustomer
      noteTitle: $noteTitle
      noteDescription: $noteDescription
      uploadDocument: $uploadDocument
      documentName: $documentName
    ) {
      id
      createdAt
      status
      title
      doctorName
      doctorEmail
      diagnosis
    }
  }
`;

export const LINK_CLAIMS_TO_CLAIM_ASSESSMENT = gql`
  mutation LinkClaimsToClaimAssessment(
    $claimAssessmentId: String!
    $claimIds: [String!]!
  ) {
    linkClaimsToClaimAssessment(
      claimAssessmentId: $claimAssessmentId
      claimIds: $claimIds
    ) {
      id
      createdAt
      claims {
        id
        status
        amount
        claimType {
          id
          type
        }
        createdAt
      }
    }
  }
`;
