import Input from 'components/Input';
import { ChangeEvent } from 'react';

import { MultipleLineRow } from '..';
import TextInformation from '../TextInformation';
import * as styles from './styles';

export interface MultipleLineInformationProps {
  cell?:
    | JSX.Element
    | number
    | string
    | boolean
    | string[]
    | Record<string, unknown>;
  multipleCells?: MultipleLineRow[];
  editable?: boolean;
  editMode?: boolean;
  handleChange?: (event: ChangeEvent<HTMLInputElement>, id: string) => void;
  placeholder?: string;
  value?: string;
  id: string;
  className?: string;
  errorMessage?: string;
}

const MultipleLineInformation = ({
  cell,
  multipleCells,
  editMode,
  editable,
  errorMessage,
  handleChange,
  id: objectId,
}: MultipleLineInformationProps) => {
  if (multipleCells) {
    return (
      <div className={styles.rowsWrapper}>
        {multipleCells.map(({ label, data, id: fieldId }, index) => {
          const labelContent =
            typeof label === 'string' ? (
              <div className={styles.rowLabel}>
                <span>{label}</span>
              </div>
            ) : (
              <div className={styles.rowLabel} />
            );
          const labelContentEditMode =
            typeof label === 'string' ? (
              <div className={styles.rowLabel}>
                <span>{label}</span>
              </div>
            ) : (
              label
            );
          return (
            <div
              className={styles.cardListRow}
              key={typeof label === 'string' ? label : `${fieldId}${index}`}
            >
              {editMode ? labelContentEditMode : labelContent}
              {editMode && editable ? (
                data !== undefined ? (
                  <Input
                    className={styles.input}
                    value={
                      (cell as Record<string, any>)[fieldId as string] ?? ''
                    }
                    type="text"
                    id={fieldId}
                    onChange={(e) => {
                      handleChange?.(e, objectId);
                    }}
                    color="gray"
                    error={!!errorMessage}
                    errorMessage={errorMessage}
                  />
                ) : null
              ) : (
                <TextInformation cell={data ?? ''} id={data ?? ''} />
              )}
            </div>
          );
        })}
      </div>
    );
  }
  return <></>;
};

export default MultipleLineInformation;
