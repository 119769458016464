import { Note } from 'models/Note';
import { PaymentMethod } from 'models/PaymentMethod';
import { Policy } from 'models/policy';
import { User } from 'models/User';

import { OverviewTabView } from './OverviewTab.view';

interface Props {
  policies?: Policy[];
  customer?: User;
  customerLoading: boolean;
  paymentMethods?: PaymentMethod[];
  pinnedNotes?: Note[];
}

export const OverviewTab = ({
  policies,
  customer,
  customerLoading,
  paymentMethods,
  pinnedNotes,
}: Props) => {
  return (
    <OverviewTabView
      policies={policies}
      customer={customer}
      customerLoading={customerLoading}
      paymentMethods={paymentMethods}
      pinnedNotes={pinnedNotes}
    />
  );
};
