import { SelectButtonOption } from 'components/SelectButton';
import { StatusUpdateFormQuestion } from 'components/StatusUpdateModal/models';
import { StatusData } from 'pages/claims/template/models';
import { claimAssessmentBadgeColorMapper } from 'shared/claimAssessments/badgeColorMapper';
import {
  ClaimAssessmentStatus,
  ClaimAssessmentStatuses,
} from 'shared/claimAssessments/models';
import { claimAssessmentStatusNameMapper } from 'shared/claimAssessments/statusNameMapper';

export const claimAssessmentStatusOptions: SelectButtonOption<ClaimAssessmentStatus>[] =
  ClaimAssessmentStatuses.map((statusId) => ({
    id: statusId,
    label: claimAssessmentStatusNameMapper[statusId] ?? '',
    title: claimAssessmentStatusNameMapper[statusId] ?? '',
    color: claimAssessmentBadgeColorMapper[statusId],
  }));

export const retrieveFormAndFooterTextByStatus = (
  status: ClaimAssessmentStatus
): StatusData => ({
  form: formMapper[status],
  footerText: footerTextMapper(status),
});

const noteForCustomerForm: StatusUpdateFormQuestion = {
  id: 'noteForCustomer',
  label: 'Note for customer',
  data: {
    type: 'TOGGLE',
    defaultValue: true,
    description:
      'Will be shown to the customer as long as the status remains the same.',
  },
  required: true,
  errorMessage: 'Please select an option.',
};

const titleForm: StatusUpdateFormQuestion = {
  id: 'noteTitle',
  label: 'Title',
  data: { type: 'TEXT', placeholder: 'E.g. We are reviewing the details' },
  fieldDependencies: [
    {
      questionId: 'noteForCustomer',
      requiredValue: true,
    },
  ],
  required: true,
};

const descriptionForm: StatusUpdateFormQuestion = {
  id: 'noteDescription',
  label: 'Description',
  data: {
    type: 'TEXT_AREA',
    placeholder: 'It can take up to 2 weeks. You’ll get an update by email.',
  },
  fieldDependencies: [
    {
      questionId: 'noteForCustomer',
      requiredValue: true,
    },
  ],
};

const uploadForm: StatusUpdateFormQuestion = {
  id: 'uploadDocument',
  label: 'Upload document',
  data: {
    type: 'UPLOAD',
    fileName: { placeholder: 'Enter document friendly name', id: 'fileName' },
  },
};

const formMapper: Record<ClaimAssessmentStatus, StatusUpdateFormQuestion[]> = {
  CUSTOMER_CONTACTED: [
    noteForCustomerForm,
    titleForm,
    descriptionForm,
    uploadForm,
  ],
  DOCTOR_CONTACTED: [
    noteForCustomerForm,
    titleForm,
    descriptionForm,
    uploadForm,
  ],
  RELEASE_SENT: [noteForCustomerForm, titleForm, descriptionForm, uploadForm],
  FORM_SENT: [noteForCustomerForm, titleForm, descriptionForm, uploadForm],
  NEGATIVE: [noteForCustomerForm, titleForm, descriptionForm, uploadForm],
  POSITIVE: [noteForCustomerForm, titleForm, descriptionForm, uploadForm],
  // TODO: Modals are not designed for version 2 statuses yet, but without forms you cannot change status to these
  CUSTOMER_FOLLOW_UP: [
    noteForCustomerForm,
    titleForm,
    descriptionForm,
    uploadForm,
  ],
  DOCTOR_FOLLOW_UP: [
    noteForCustomerForm,
    titleForm,
    descriptionForm,
    uploadForm,
  ],
  PROVIDER_FOLLOW_UP: [
    noteForCustomerForm,
    titleForm,
    descriptionForm,
    uploadForm,
  ],
};

const footerTextMapper = (
  status: ClaimAssessmentStatus
): string | undefined => {
  return 'Once confirmed, claim assessment status will be updated and documents will be added to the claim assessment.';
};
