import { InputProps } from '../Input';
import { NumberInput } from '../NumberInput';

export const CurrencyInput = ({
  onChange,
  ...props
}: InputProps & { onChange: (value: string) => void }) => (
  <NumberInput
    {...props}
    onChange={(e) => onChange(e.target.value)}
    prefix="€"
    suffix="EUR"
  />
);
