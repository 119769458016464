import { useMutation } from '@apollo/client';
import { ExternalLinkIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import Badge from 'components/Badge';
import Button from 'components/Button';
import { CheckBox } from 'components/CheckBox';
import FileErrorCard from 'components/FileErrorCard';
import Link from 'components/Link';
import Modal from 'components/Modal';
import { PolicyCard } from 'components/PolicyCard';
import dayjs from 'dayjs';
import { ClaimAssessment } from 'models/ClaimAssessment';
import { retrieveClaimAssessmentsDetailsPath } from 'pages/claimAssessments/template/utils/utils';
import {
  UPDATE_SCANNED_DOCUMENT,
  UPDATE_SCANNED_DOCUMENTS_USER_CLAIM_ASSESSMENTS_RELATION,
} from 'pages/scannedDocuments/scannedDocuments.mutations';
import { GET_SCANNED_DOCUMENT_BY_ID } from 'pages/scannedDocuments/scannedDocuments.queries';
import { ScannedDocument } from 'pages/scannedDocuments/scannedDocuments.types';
import { getPolicyDetailsBaseURLFromInsuranceType } from 'pages/scannedDocuments/scannedDocuments.utils';
import { useEffect, useState } from 'react';
import { claimAssessmentBadgeColorMapper } from 'shared/claimAssessments/badgeColorMapper';
import { claimAssessmentStatusNameMapper } from 'shared/claimAssessments/statusNameMapper';
import { insuranceIconMapper } from 'shared/insurances/insuranceIconMapper';
import { insuranceNameMapper } from 'shared/insurances/insuranceNameMapper';

import * as styles from './ScannedDocument.ConnectAPVs.styles';

const sortByCreatedAtDescending = (a: ClaimAssessment, b: ClaimAssessment) => {
  if (a?.createdAt && b?.createdAt) {
    return b.createdAt.localeCompare(a.createdAt);
  }
  return 0;
};

export const ConnectAPVs = ({
  scannedDocument,
}: {
  scannedDocument: ScannedDocument;
}) => {
  const { policyNumber, connectedUserClaimAssessments = [] } = scannedDocument;

  const matchingPolicy =
    scannedDocument.userPolicy ?? scannedDocument.relatedUserPolicy;

  const possibleAPVs = matchingPolicy?.userClaimAssessments ?? [];
  const sortedPossibleAPVs = [...possibleAPVs].sort(sortByCreatedAtDescending);

  const [showModal, setShowModal] = useState(false);

  const initiallySelectedAPVs: Record<string, boolean> =
    connectedUserClaimAssessments.reduce(
      (acc, claimAssessment) => ({
        ...acc,
        [claimAssessment.id]: true,
      }),
      {}
    );

  const [selectedAPVs, setselectedAPVs] = useState<Record<string, boolean>>(
    initiallySelectedAPVs
  );

  const connectedClaimAssessmentIds = Object.entries(selectedAPVs).flatMap(
    ([apvId, value]) => (value ? [apvId] : [])
  );

  const [changeConnectedAPVs, changeConnectedAPVsResult] = useMutation(
    UPDATE_SCANNED_DOCUMENTS_USER_CLAIM_ASSESSMENTS_RELATION,
    {
      variables: {
        scannedDocumentId: scannedDocument.id,
        claimAssessmentIds: connectedClaimAssessmentIds,
      },
    }
  );

  const [setDocumentPolicyIdAndDone, setDocumentPolicyIdAndDoneResult] =
    useMutation(UPDATE_SCANNED_DOCUMENT, {
      variables: {
        id: scannedDocument.id,
        userPolicyId: matchingPolicy?.id,
        status: 'DONE',
      },
      refetchQueries: [GET_SCANNED_DOCUMENT_BY_ID],
    });

  const handleConfirm = async () => {
    await changeConnectedAPVs();
    await setDocumentPolicyIdAndDone();
    if (
      !changeConnectedAPVsResult.error &&
      !setDocumentPolicyIdAndDoneResult.error
    ) {
      setShowModal(false);
    }
  };

  useEffect(() => {}, [matchingPolicy?.id, scannedDocument.policyNumber]);

  const confirmationRequired = matchingPolicy && !scannedDocument.userPolicyId;

  const archived = !!scannedDocument.archivedAt;

  if (sortedPossibleAPVs.length === 0) {
    return null;
  }

  return (
    <>
      {showModal && (
        <Modal
          title="Connect document to APV(s)"
          open={showModal}
          setOpen={setShowModal}
          confirmButtonLabel="Connect"
          handleConfirm={handleConfirm}
          scrollable
        >
          <div className="flex flex-col">
            {confirmationRequired && (
              <FileErrorCard
                open={true}
                className="mb-5 mt-5"
                title="Unconfirmed relation"
                hideCloseButton={true}
                handleClose={() => {}}
                errorType="WARNING"
                description="Accepting this action will also confirm that below policy belongs to this document."
              />
            )}

            <p className={classNames(styles.label, 'mt-5')}>Matching policy</p>
            <PolicyCard
              title={
                (matchingPolicy?.insuranceType &&
                  insuranceNameMapper[matchingPolicy?.insuranceType]) ??
                ''
              }
              icon={
                (matchingPolicy?.insuranceType &&
                  insuranceIconMapper[matchingPolicy.insuranceType]) ??
                ''
              }
              link={
                getPolicyDetailsBaseURLFromInsuranceType(
                  matchingPolicy?.insuranceType,
                  matchingPolicy?.id
                ) ?? ''
              }
              insuredPerson={`${matchingPolicy?.user.firstName} ${matchingPolicy?.user.lastName}`}
              policyNumber={matchingPolicy?.policyNumber}
              regionOfPurchase={matchingPolicy?.regionOfPurchase}
              smallVersion
              openInNewTab
            />

            <Link
              color="primary"
              text="Document link"
              href={scannedDocument.url}
              IconComponent={ExternalLinkIcon}
              iconLeft={false}
              target="_blank"
              className="mb-6"
            />

            <p className={classNames(styles.label, 'mt-8', 'mb-5')}>
              Select APVs
            </p>
            <div className="mb-6 mt-3 flex flex-col items-stretch gap-3">
              {sortedPossibleAPVs?.map((apv) => {
                return (
                  <div className="mb-4 flex gap-2" key={apv.id}>
                    <CheckBox
                      checked={selectedAPVs[apv.id ?? '']}
                      setChecked={(isSelected: boolean) =>
                        setselectedAPVs({
                          ...selectedAPVs,
                          [apv.id ?? '']: isSelected,
                        })
                      }
                    />
                    <div className="flex w-full flex-col">
                      <a
                        className={classNames(styles.claimCard, {
                          [styles.selected]: selectedAPVs[apv.id ?? ''],
                        })}
                        href={retrieveClaimAssessmentsDetailsPath(apv.id) ?? ''}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div key={apv.id} className={styles.cardContent}>
                          <div>
                            <p className="text-sm">{apv.title}</p>
                            <p className="mt-2 text-xs text-gray-500">
                              {dayjs(apv.createdAt).format('DD MMM YYYY')}
                            </p>
                          </div>
                          {apv.status && (
                            <Badge
                              color={
                                claimAssessmentBadgeColorMapper[apv.status]
                              }
                              badgeType="full"
                            >
                              {claimAssessmentStatusNameMapper[apv.status]}
                            </Badge>
                          )}
                        </div>
                      </a>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Modal>
      )}
      <Button
        buttonType="primary"
        disabled={!policyNumber || archived}
        onClick={() => setShowModal(true)}
        loading={
          changeConnectedAPVsResult.loading ||
          setDocumentPolicyIdAndDoneResult.loading
        }
      >
        Connect
      </Button>
    </>
  );
};
