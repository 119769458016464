import { ChangeEvent, useState } from 'react';

import Input, { InputProps } from '../Input';

type NumberInputProps = InputProps;

const strToNumber = (value: string): number => {
  const withConvertedDecimalSeparator = value.replace(/,/, '.');
  return parseFloat(withConvertedDecimalSeparator);
};

export const NumberInput = ({
  value,
  onChange,
  ...props
}: NumberInputProps) => {
  const [initialValue] = useState<string>(value?.toString() ?? '');

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const parsedNumber = strToNumber(e.target.value);

    if (!Number.isNaN(parsedNumber)) {
      const newE = {
        ...e,
        target: {
          ...e.target,
          value: parsedNumber.toFixed(2),
          id: e.target.id,
        },
      };

      onChange?.(newE);
    }
  };

  return (
    <Input
      {...props}
      type="text"
      pattern="[0-9.,]*"
      defaultValue={
        value !== null && value !== undefined && value !== ''
          ? parseFloat(value.toString()).toFixed(2)
          : ''
      }
      onChange={handleOnChange}
      onBlur={(e) => {
        const parsedNumber = strToNumber(e.target.value);

        e.target.value = !Number.isNaN(parsedNumber)
          ? parsedNumber.toFixed(2)
          : (e.target.value = initialValue);
      }}
    />
  );
};
