import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { RowConfig } from 'components/TableRefactor/types';
import { GenericClaim } from 'pages/claims/generic/models';
import { retrieveReadableClaimTypes } from 'pages/claims/template/components/ClaimsDetails/utils';
import { statusMapping } from 'pages/claims/template/utils/utils';
import { getClaimDetailsRouteMapper } from 'shared/claims/claimRouteMapper';
import { Country } from 'shared/countries/models';
import { policyMappers } from 'shared/insurances';
import { InsuranceType } from 'shared/insurances/types';

export const rowConfig = (
  insuranceType: InsuranceType,
  currentRegion?: Country
): RowConfig => ({
  getResourceLink: (data: GenericClaim) =>
    getClaimDetailsRouteMapper(currentRegion)[insuranceType](data.id) ?? '',
  cells: [
    {
      type: 'TEXT',
      textColor: 'dark',
      props: { getText: (data: GenericClaim) => data.user.firstName ?? '' },
    },
    {
      type: 'TEXT',
      textColor: 'dark',
      props: { getText: (data: GenericClaim) => data.user.lastName ?? '' },
    },
    {
      type: 'STATUS',
      textColor: 'dark',
      props: {
        getStatus: ({ status }: GenericClaim) =>
          statusMapping(
            status && policyMappers.GENERIC?.claim?.claimStatusMapper
              ? policyMappers.GENERIC.claim.claimStatusMapper[status]
              : '',
            status && policyMappers.GENERIC?.claim?.badgeColorMapper
              ? policyMappers.GENERIC.claim.badgeColorMapper[status]
              : 'blue'
          ),
      },
    },
    {
      type: 'TEXT',
      textColor: 'light',
      props: {
        getText: (data: GenericClaim) =>
          data.claimType?.length
            ? retrieveReadableClaimTypes(
                data.claimType.length,
                policyMappers.GENERIC?.claim?.typeNameMapper[
                  data.claimType[0].type
                ] ?? ''
              )
            : '',
      },
    },
    {
      type: 'TEXT',
      textColor: 'light',
      props: {
        getText: (data: GenericClaim) =>
          data.amount ? englishFormattedEuroCurrency(data.amount, true) : '-',
      },
    },
    {
      type: 'DATE',
      textColor: 'light',
      props: { getDate: (data: GenericClaim) => data.createdAt },
    },
    {
      type: 'TEXT',
      textColor: 'light',
      props: { getText: (data: GenericClaim) => data.claimNumber ?? '' },
    },
    {
      type: 'DATE',
      textColor: 'light',
      props: { getDate: (data: GenericClaim) => data.customerPaidOn ?? '' },
    },
    ...(currentRegion === 'eu'
      ? [
          {
            type: 'TEXT' as const,
            textColor: 'light' as const,
            props: {
              getText: (data: GenericClaim) =>
                data?.regionOfPurchase?.toUpperCase() ?? '',
            },
          },
        ]
      : []),
  ],
});
