import { Country } from 'shared/countries/models';
import { GenericInsuranceType, InsuranceType } from 'shared/insurances/types';

export type TemplateInsuranceType =
  | Extract<
      InsuranceType,
      | 'DENTAL'
      | 'HOUSEHOLD'
      | 'LIABILITY'
      | 'LEGAL'
      | 'LIFE'
      | 'BIKE'
      | 'DOG_LIABILITY'
      | 'INCOMING_ES'
      | 'DOG_LIABILITY_ES'
    >
  | GenericInsuranceType;

export type HRTemplateInsuranceType =
  | Extract<InsuranceType, 'PUBLIC_HEALTH' | 'PRIVATE_HEALTH'>
  | Extract<
      GenericInsuranceType,
      'COMPANY_PENSION' | 'COMPANY_HEALTH' | 'COMPANY_LIFE'
    >;

export const insurancePaths = [
  'car',
  'travel',
  'pet-health',
  'company-health',
  'company-life',
  'disability',
  'pension',
  'basis-pension',
  'expat-health-es',
  'travel-fr',
  'dental',
  'household',
  'liability',
  'legal',
  'life',
  'bike',
  'dog-liability',
  'generic',
  'expat-health-eu',
  'expat-health-long-term',
  'company-pension',
  'dog-liability-es',
  'bike-es',
  'dental-es',
  'life-eu',
] as const;

export type PolicyTemplateInsurancePath = typeof insurancePaths[number];

export const hrInsurancePaths = [
  'public-health',
  'company-pension',
  'company-health',
  'company-life',
  'private-health',
] as const;

export type PolicyHRTemplateInsurancePath = typeof hrInsurancePaths[number];

export const getTemplateInsuranceTypeCountry = (): Record<
  TemplateInsuranceType | HRTemplateInsuranceType,
  Country[]
> => ({
  CAR: ['de'],
  TRAVEL: ['de'],
  PET_HEALTH: ['de'],
  COMPANY_HEALTH: ['de'],
  COMPANY_LIFE: ['de'],
  DISABILITY: ['de'],
  PENSION: ['de'],
  BASIS_PENSION: ['de'],
  INCOMING_ES: ['es'],
  DENTAL: ['de'],
  HOUSEHOLD: ['de'],
  LIABILITY: ['de'],
  LEGAL: ['de'],
  LIFE: ['de'],
  BIKE: ['de'],
  DOG_LIABILITY: ['de'],
  GENERIC: ['de'],
  TRAVEL_FR: ['fr'],
  INCOMING_EU: [
    'eu',
    'nl',
    'it',
    'at',
    'be',
    'cy',
    'ee',
    'fi',
    'gr',
    'hr',
    'lt',
    'lv',
    'mc',
    'mt',
    'pt',
    'se',
    'fr',
  ],
  INCOMING_LT: [
    'eu',
    'de',
    'es',
    'at',
    'be',
    'cy',
    'ee',
    'fi',
    'fr',
    'gr',
    'hr',
    'it',
    'lt',
    'lv',
    'mc',
    'mt',
    'nl',
    'pt',
    'se',
  ],
  COMPANY_PENSION: ['de'],
  PUBLIC_HEALTH: ['de'],
  DOG_LIABILITY_ES: ['es'],
  PRIVATE_HEALTH: ['de'],
  BIKE_ES: ['es'],
  DENTAL_ES: ['es'],
  LIFE_EU: ['eu', 'de', 'at', 'nl', 'it', 'es', 'be', 'fr'],
});
