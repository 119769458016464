import { gql } from '@apollo/client';

export const GET_COMPANIES = gql`
  query GetCompanies(
    $offset: Float!
    $limit: Float!
    $searchString: String!
    $sortColumn: String!
    $sortOrder: String!
    $filterType: String!
  ) {
    companies(
      offset: $offset
      limit: $limit
      searchString: $searchString
      sortColumn: $sortColumn
      sortOrder: $sortOrder
      filterType: $filterType
    ) {
      id
      name
      sources
      createdAt
      active
      activeUsers
      type
    }
    companiesCount(
      searchString: $searchString
      sortColumn: $sortColumn
      sortOrder: $sortOrder
      filterType: $filterType
    )
  }
`;

export const GET_COMPANY = gql`
  query GetCompany($id: String!) {
    company(id: $id) {
      id
      name
      createdAt
      active
      type
      sources
      activeUsers
      companyDetails
      internalUploads {
        id
        blobName
        url
        resizedUrl
        createdAt
        uploadType
        category
        friendlyName
        isVisibleToCustomer
        isVisibleToHr
        isVisibleToProvider
        archivedAt
        internalNotes
        userClaimAssessmentId
        companyId
      }
    }
  }
`;

export const GET_COMPANY_BY_NAME = gql`
  query GetCompanyByName($name: String!) {
    companyByName(name: $name) {
      id
      name
      createdAt
      active
      type
      sources
      activeUsers
      companyDetails
      internalUploads {
        id
        blobName
        url
        resizedUrl
        createdAt
        uploadType
        category
        friendlyName
        isVisibleToCustomer
        isVisibleToHr
        isVisibleToProvider
        archivedAt
        internalNotes
        userClaimAssessmentId
        companyId
      }
    }
  }
`;

export const GET_HR_COMPANY_BY_NAME = gql`
  query GetHrCompanyByName($name: String!) {
    companyByName(name: $name) {
      id
      name
      createdAt
      active
      type
      sources
      activeUsers
      companyDetails
      hrVisibleInternalUploads {
        id
        blobName
        url
        resizedUrl
        createdAt
        uploadType
        category
        friendlyName
        isVisibleToCustomer
        isVisibleToHr
        isVisibleToProvider
        archivedAt
        internalNotes
        userClaimAssessmentId
        companyId
      }
    }
  }
`;
