import CompanyDetailsCard from 'components/CompanyDetailsCard';
import { CompanyDocuments } from 'components/CompanyDocuments';
import { HRViewCompany } from 'pages/hrDashboard';
import { orderCompanyDetailsByVertical } from 'shared/hrCompanyDetails/orderCompanyDetailsByType';

import { CompanyPoliciesCard } from './components/CompanyPoliciesCard';
import * as styles from './styles';

interface OverviewTabProps {
  company: HRViewCompany;
  metricsTitle: string;
  policiesTitle: string;
}

const OverviewTab = ({
  company,
  metricsTitle,
  policiesTitle,
}: OverviewTabProps) => {
  const updatedCompany = {
    ...company,
    companyDetails: orderCompanyDetailsByVertical(company.companyDetails),
  };

  return (
    <>
      <div className={styles.container}>
        <CompanyPoliciesCard
          company={updatedCompany}
          cardTitle={policiesTitle}
        />
        <CompanyDetailsCard company={updatedCompany} cardTitle={metricsTitle} />
      </div>
      <CompanyDocuments
        companyId={company.id}
        documents={company.hrVisibleInternalUploads}
        isHRView={true}
      />
    </>
  );
};

export default OverviewTab;
