import Card from 'components/Card';
import CardSection from 'components/Card/CardSection';
import dayjs from 'dayjs';
import { ClaimAssessmentsData } from 'pages/claimAssessments';
import { retrieveClaimAssessmentsDetailsPath } from 'pages/claimAssessments/template/utils/utils';
import { ScannedDocument } from 'pages/scannedDocuments/scannedDocuments.types';
import { insuranceIconMapper } from 'shared/insurances/insuranceIconMapper';

import { ConnectAPVs } from './Connect/ScannedDocument.ConnectAPVs';
import { CreateAPV } from './CreateAPV/ScannedDocument.CreateAPV';
import * as styles from './ScannedDocument.APVsList.styles';

export const APVsList = ({
  scannedDocument,
}: {
  scannedDocument: ScannedDocument;
}) => {
  const relatedPolicy = scannedDocument.relatedUserPolicy;

  const matchingPolicy =
    scannedDocument.userPolicy ?? scannedDocument.relatedUserPolicy;

  const matchingPolicyHasAPVs = !!matchingPolicy?.userClaimAssessments?.length;

  const connectedAPVs = scannedDocument.connectedUserClaimAssessments ?? [];

  return (
    <Card
      title="APVs"
      boldTitle={false}
      actionButton={
        <>
          <CreateAPV
            scannedDocument={scannedDocument}
            claimAssessmentsTemplateData={ClaimAssessmentsData}
          />
          {matchingPolicyHasAPVs && (
            <ConnectAPVs scannedDocument={scannedDocument} />
          )}
        </>
      }
    >
      {connectedAPVs.length > 0 ? (
        connectedAPVs.map((apv) => (
          <a
            href={retrieveClaimAssessmentsDetailsPath(apv.id) ?? ''}
            key={apv.id}
            target="_blank"
            rel="noreferrer"
          >
            <CardSection className={styles.cardContent}>
              <div className={styles.iconAndTextWrapper}>
                {relatedPolicy?.insuranceType && (
                  <img
                    className={styles.claimIcon}
                    src={insuranceIconMapper[relatedPolicy.insuranceType]}
                    alt=""
                  />
                )}
                <div>
                  <p>{apv.title}</p>
                  <p className="mt-1 text-xs text-gray-500">
                    {dayjs(apv.createdAt).format('DD MMM YYYY')}
                  </p>
                </div>
              </div>
            </CardSection>
          </a>
        ))
      ) : (
        <div className={styles.noDocumentsContainer}>
          {matchingPolicyHasAPVs ? (
            <p className={styles.noDocuments}>No APVs connected yet.</p>
          ) : (
            <p className={styles.noDocuments}>
              No APVs to connect to could be found.
            </p>
          )}
        </div>
      )}
    </Card>
  );
};
