import { capitalizeName } from '@getpopsure/public-utility';
import { PolicyTemplateData } from 'pages/policies/template/models/PolicyTemplateData';
import { insuranceNameMapper } from 'shared/insurances/insuranceNameMapper';
import { policyDocumentCategories } from 'shared/policyDocumentCategories';

import { NUMBER_OF_POLICIES_PER_PAGE } from '../template/constants';
import { getDocumentSections } from '../template/defaultPolicyDocumentsConfig';
import { TemplateInsuranceType } from '../template/models/InsuranceTypes';
import { getDbInsuranceType } from '../template/utils';
import { getOverviewTabCards } from './data/overviewTabData';
import { getQuestionnaireData } from './data/questionnaireData';
import {
  extendedValidatorObject,
  getHouseholdModals,
  householdNextStatuses,
  householdStatusOptions,
  retrieveModalId,
} from './data/statusData';
import { rowConfig, tableHeaders } from './data/tableData';
import { tableFilters } from './data/tableFilters';
import { GET_HOUSEHOLD_POLICIES } from './graphql/policies';
import { GET_HOUSEHOLD_POLICY } from './graphql/singlePolicy';
import { UPDATE_HOUSEHOLD_QUESTIONNAIRE } from './graphql/updateQuestionnaire';
import { UPDATE_HOUSEHOLD_POLICY_STATUS } from './graphql/updateStatus';
import { HouseholdPolicyData } from './models/HouseholdPolicy';

export const getHouseholdPoliciesTemplateData =
  (): PolicyTemplateData<HouseholdPolicyData> => {
    const insurancePath = 'household';
    const insuranceType = getDbInsuranceType(
      insurancePath
    ) as TemplateInsuranceType;
    const insuranceName = insuranceNameMapper[insuranceType];

    return {
      insuranceType,
      navigation: {
        current: `policies-${insurancePath}`,
      },
      queries: {
        allPolicies: GET_HOUSEHOLD_POLICIES,
        policy: GET_HOUSEHOLD_POLICY,
      },
      mutations: {
        statusChange: UPDATE_HOUSEHOLD_POLICY_STATUS,
        editQuestionnaire: UPDATE_HOUSEHOLD_QUESTIONNAIRE,
      },
      copy: {
        table: {
          title: `${insuranceName} policies`,
          searchPlaceholder:
            'Search by name, email, policy number, policy id or utm source',
        },
        details: {
          pageTitle: `${insuranceName} policy - Feather Admin Panel`,
          title: insuranceName,
        },
      },
      features: {
        overviewTable: {
          numberOfRowsPerPage: NUMBER_OF_POLICIES_PER_PAGE,
          headersConfig: tableHeaders,
          rowConfig,
          filtersConfig: tableFilters,
        },
        details: {
          queryVariables: {
            insuranceType,
          },
          insuredPersonName: (data?: HouseholdPolicyData) =>
            capitalizeName({
              firstName: data?.householdPolicy.insuredPerson?.firstName ?? '',
              lastName: data?.householdPolicy.insuredPerson?.lastName ?? '',
            }) ?? '',
          overviewTab: {
            getCards: getOverviewTabCards,
          },
        },
        statusChange: {
          statusOptions: householdStatusOptions,
          statusChangeOptions: householdNextStatuses,
          getModals: getHouseholdModals,
          retrieveModalId,
          extendedValidatorObject,
          additionalQueryVariables: { insuranceType },
        },
        documents: {
          uploadOptions: policyDocumentCategories,
          getDocumentSections: (data?: HouseholdPolicyData) =>
            getDocumentSections(data?.householdPolicy),
        },
        questionnaire: {
          cardTitle: 'Personal information',
          editable: true,
          getQuestionnaireData,
        },
      },
    };
  };
