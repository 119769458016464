import { InsuranceType } from 'shared/insurances/types';
import { z } from 'zod';

export const TaskFileSchema = z.object({
  fileName: z.string(),
  blobName: z.string(),
  url: z.string(),
});

export type TaskFile = z.infer<typeof TaskFileSchema>;

const InputActionSchema = z.object({
  id: z.string(),
  type: z.literal('INPUT'),
  attributes: z.object({
    name: z.string(),
    placeholder: z.string(),
  }),
});

const TextareaActionSchema = z.object({
  id: z.string(),
  type: z.literal('TEXTAREA'),
  attributes: z.object({
    name: z.string(),
    placeholder: z.string(),
  }),
});

const AddressActionSchema = z.object({
  id: z.string(),
  type: z.literal('ADDRESS'),
});

const SubmitActionSchema = z.object({
  id: z.string(),
  type: z.literal('SUBMIT'),
  attributes: z.object({
    value: z.string(),
  }),
});

const FileActionSchema = z.object({
  id: z.string(),
  type: z.literal('FILE'),
});

const DateActionSchema = z.object({
  id: z.string(),
  type: z.literal('DATE'),
  attributes: z.object({
    name: z.string(),
  }),
});

const CurrencyActionSchema = z.object({
  id: z.string(),
  type: z.literal('CURRENCY'),
  attributes: z.object({
    name: z.string(),
  }),
});

const LinkActionSchema = z.object({
  id: z.string(),
  type: z.literal('LINK'),
  attributes: z.object({
    href: z.string(),
  }),
});

const DownloadActionSchema = z.object({
  id: z.string(),
  type: z.literal('DOWNLOAD'),
  attributes: z.object({
    file: TaskFileSchema,
  }),
});

const DownloadActionInputSchema = z.object({
  id: z.string(),
  type: z.literal('DOWNLOAD'),
  attributes: z.object({
    file: z.instanceof(File),
    name: z.string(),
  }),
});

const BaseActionSchema = z.union([
  InputActionSchema,
  TextareaActionSchema,
  AddressActionSchema,
  SubmitActionSchema,
  FileActionSchema,
  DateActionSchema,
  CurrencyActionSchema,
  LinkActionSchema,
]);

const ActionSchema = z.union([BaseActionSchema, DownloadActionSchema]);

const ActionInputSchema = z.union([
  BaseActionSchema,
  DownloadActionInputSchema,
]);

export type TaskAction = z.infer<typeof ActionSchema>;

export const ACTION_TYPES = [
  'INPUT',
  'TEXTAREA',
  'ADDRESS',
  'SUBMIT',
  'FILE',
  'DATE',
  'CURRENCY',
  'DOWNLOAD',
  'LINK',
] as const;

export type ActionType = typeof ACTION_TYPES[number];

export const TaskMetadataSchema = z.object({
  title: z.string(),
  subtitle: z.string().optional(),
  description: z.string().optional(),
  taskTemplateId: z.string().optional(),
});

export const NewTaskPayloadSchema = z.object({
  metadata: TaskMetadataSchema,
  actions: z.array(ActionInputSchema),
});

const TaskDescriptionBaseSchema = z.object({
  type: z.string(),
  metadata: TaskMetadataSchema.optional(),
  attributes: z
    .object({
      insuranceType: z.string().optional(),
      claimId: z.string().optional(),
      policyId: z.string().optional(),
      taskTemplateId: z.string().optional(),
    })
    .optional(),
});

const TaskDescriptionSchema = TaskDescriptionBaseSchema.extend({
  actions: z.array(ActionSchema).optional(),
});

const TaskDescriptionInputSchema = TaskDescriptionBaseSchema.extend({
  type: z.literal('GENERIC'),
  metadata: TaskMetadataSchema,
  actions: z.array(ActionInputSchema),
});

const TASK_STATUSES = ['COMPLETED', 'OPEN', 'CANCELED'] as const;

export type TaskStatus = typeof TASK_STATUSES[number];

const TASK_CATEGORIES = ['SYSTEM', 'GENERIC'] as const;

const CreateTaskInputSchema = z.object({
  userId: z.string(),
  issueId: z.string().optional(),
  description: TaskDescriptionInputSchema,
});

const CreateTaskPayloadSchema = z.object({
  input: CreateTaskInputSchema,
});

const UpdateTaskInputSchema = z.object({
  status: z.enum(TASK_STATUSES).optional(),
  description: TaskDescriptionInputSchema.optional(),
});

const UpdateTaskPayloadSchema = z.object({
  id: z.string(),
  input: UpdateTaskInputSchema,
});

export type CreateTaskPayload = z.infer<typeof CreateTaskPayloadSchema>;

export type UpdateTaskPayload = z.infer<typeof UpdateTaskPayloadSchema>;

export const FileDataSchema = z.object({
  files: z.array(TaskFileSchema),
});

export const DataSchema = z.union([FileDataSchema, z.record(z.string())]);

export type TaskEngineDataType = z.infer<typeof DataSchema>;

const TypeSchema = z.object({
  id: z.string(),
  createdAt: z.string(),
  finishedAt: z.string().optional(),
  status: z.enum(TASK_STATUSES),
  category: z.enum(TASK_CATEGORIES),
  data: DataSchema,
  description: TaskDescriptionSchema,
});

export type Task = z.infer<typeof TypeSchema>;

export const InitialAddressSchema = z.object({
  city: z.string(),
  street: z.string(),
  postcode: z.string(),
  houseNumber: z.string(),
  additionalInformation: z.string().optional().nullish(),
});

export const ValidAddressSchema = InitialAddressSchema.or(
  z.object({
    address: InitialAddressSchema,
  })
);

export interface DataPreviewProps {
  data: TaskEngineDataType;
  actionId: string;
}

type TaskTemplateData = {
  title: string;
  description: string;
  actions: Array<{
    type: ActionType;
    placeholder?: string;
  }>;
};

export type TaskTemplate = {
  id: string;
  name: string;
  tag: 'CLAIMS' | 'POLICIES';
  insuranceType: InsuranceType;
  data: TaskTemplateData;
};

export interface AdditionalFieldsAttributes {
  type: string;
  label: string;
  required: boolean;
  name: string;
  id: string;
}

export type TaskActionsFormInput = {
  id?: string;
  name?: string;
  title: string;
  description?: string;
  taskTemplateId?: string;
  actions: {
    type: ActionType;
    attributes?: {
      [field: string]: string | File | undefined;
    };
  }[];
};
