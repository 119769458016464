import { TFunction } from '@getpopsure/i18n-react';
import Button from 'components/Button';
import ColumnHeader from 'components/TableRefactor/ColumnHeader';
import SortableColumnHeader from 'components/TableRefactor/SortableColumnHeader';
import { HeaderConfig, RowConfig } from 'components/TableRefactor/types';
import {
  GET_HR_PUBLIC_POLICY,
  GET_PUBLIC_POLICIES,
} from 'graphql/publicHealth/hr/queries';
import { PublicHealthPolicy } from 'models/publicHealthPolicy';
import { HRTemplateInsuranceType } from 'pages/policies/template/models/InsuranceTypes';
import { PolicyTemplateData } from 'pages/policies/template/models/PolicyTemplateData';
import {
  getDbHRInsuranceType,
  retrieveHRPoliciesDetailsPath,
} from 'pages/policies/template/utils';
import { getHRPublicHealthStatus } from 'shared/mapHRPublicHealthStatus';

import { getDocumentSections } from './data/documents';
import { getOverviewTabCards } from './data/overviewTabCards';
import { getHRStatus } from './getHRStatus';

const insurancePath = 'public-health';
const insuranceType = getDbHRInsuranceType(
  insurancePath
) as HRTemplateInsuranceType;

const getTableHeaders = (t: TFunction): HeaderConfig[] => [
  {
    id: 'firstname',
    label: t(
      'admin.hr.allpolicies.public.table.firstname.header',
      'First name'
    ),
    width: 'w-none',
    minWidth: 'min-w-[118px]',
    sticky: true,
    threshold: 'left-0',
    border: 'none',
    component: SortableColumnHeader,
  },
  {
    id: 'lastname',
    label: t('admin.hr.allpolicies.public.table.lastname.header', 'Last name'),
    width: 'w-none',
    minWidth: 'min-w-[116px]',
    sticky: true,
    threshold: 'left-[118px]',
    border: 'none',
    component: SortableColumnHeader,
  },
  {
    id: 'status',
    label: t('admin.hr.allpolicies.public.table.status.header', 'Status'),
    width: 'w-[152px]',
    minWidth: 'min-w-[152px]',
    sticky: true,
    threshold: 'left-[234px]',
    border: 'right',
    component: ColumnHeader,
  },
  {
    id: 'svnr',
    label: t('admin.hr.allpolicies.public.table.svnr.header', 'SNVR'),
    width: 'w-[132px]',
    minWidth: 'min-w-[132px]',
    component: ColumnHeader,
  },
  {
    id: 'startdate',
    label: t(
      'admin.hr.allpolicies.public.table.startdate.header',
      'Start date'
    ),
    width: 'w-[124px]',
    minWidth: 'min-w-[14px]',
    component: SortableColumnHeader,
  },
  {
    id: 'provider',
    label: t('admin.hr.allpolicies.public.table.provider.header', 'Provider'),
    width: 'w-[96px]',
    minWidth: 'min-w-[96px]',
    component: ColumnHeader,
  },
  {
    id: 'submitted',
    label: t(
      'admin.hr.allpolicies.public.table.submittedon.header',
      'Submitted on'
    ),
    width: 'w-[154px]',
    minWidth: 'min-w-[154px]',
    component: SortableColumnHeader,
  },
];

const getRowConfig = (company: string): RowConfig => ({
  getResourceLink: (data: PublicHealthPolicy) =>
    retrieveHRPoliciesDetailsPath({
      insuranceType: 'public-health',
      id: data.id,
      company,
      country: 'de',
    }),
  cells: [
    {
      type: 'TEXT',
      props: {
        getText: (data: PublicHealthPolicy) => data.user.firstName,
      },
      textColor: 'dark',
    },
    {
      type: 'TEXT',
      props: {
        getText: (data: PublicHealthPolicy) => data.user.lastName,
      },
      textColor: 'dark',
    },
    {
      type: 'STATUS',
      props: {
        getStatus: (data: PublicHealthPolicy) =>
          getHRPublicHealthStatus(data.publicStatus),
      },
      textColor: 'dark',
    },
    {
      type: 'TEXT',
      props: {
        getText: (data: PublicHealthPolicy) => data.user.svnr,
      },
      textColor: 'light',
    },
    {
      type: 'DATE',
      props: {
        getDate: (data: PublicHealthPolicy) => data.startDate ?? '',
      },
      textColor: 'light',
    },
    {
      type: 'TEXT',
      props: {
        getText: (data: PublicHealthPolicy) => data.providerId,
      },
      textColor: 'light',
    },
    {
      type: 'DATE',
      props: {
        getDate: (data: PublicHealthPolicy) => data.createdAt,
      },
      textColor: 'light',
    },
  ],
});

export const getPublicHealthPoliciesHRTemplateData = (
  t: TFunction,
  company?: string
): PolicyTemplateData<{ hrPublicHealthPolicy: PublicHealthPolicy }> => {
  return {
    insuranceType,
    navigation: {
      current: `policies-${insurancePath}`,
    },
    queries: {
      allPolicies: GET_PUBLIC_POLICIES,
      policy: GET_HR_PUBLIC_POLICY,
    },
    mutations: {},
    copy: {
      table: {
        title: t('admin.hr.publicHealth.title', 'Public health policies'),
        searchPlaceholder: t(
          'admin.policytable.search.input',
          'Search by name or email'
        ),
      },
      details: {
        pageTitle: 'Public health policy - Feather Admin Panel',
        title: t('admin.hr.publicHealth.table.title', 'Public health'),
      },
    },
    features: {
      languageSelector: true,
      overviewTable: {
        numberOfRowsPerPage: 12,
        headersConfig: getTableHeaders(t),
        filtersConfig: [
          {
            filterType: 'MULTIPLE',
            options: getHRStatus(),
            label: t('admin.policytable.filterstatus.title', 'Status'),
            id: 'filterStatus',
          },
        ],
        rowConfig: getRowConfig(company ?? ''),
        queryVariables: {
          company,
        },
        defaultEmptyStateMessage: {
          title: t(
            'admin.hr.publicHealth.table.noPolicies.title',
            'There are no policies yet'
          ),
          description: (
            <div className="flex max-w-md flex-col items-center space-y-4">
              <p className="text-center">
                {t(
                  'admin.hr.PublicHealth.table.noPolicies.description1.label',
                  'Your employees can easily sign up for public health insurance through Feather.'
                )}
              </p>
              <Button type="button" buttonType="secondary">
                <a href="https://feather-insurance.com/health-insurance/public">
                  {t(
                    'admin.hr.publicHealth.table.noPolicies.action.learnMore.label',
                    'Learn more'
                  )}
                </a>
              </Button>
            </div>
          ),
        },
      },
      details: {
        insuredPersonName: (data?: {
          hrPublicHealthPolicy: PublicHealthPolicy;
        }) =>
          `${data?.hrPublicHealthPolicy.user.firstName ?? ''} ${
            data?.hrPublicHealthPolicy.user.lastName
          }`,
        overviewTab: { getCards: getOverviewTabCards(t) },
      },
      documents: {
        uploadOptions: [],
        getDocumentSections: getDocumentSections(t),
      },
    },
  };
};
