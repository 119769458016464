import { capitalizeName } from '@getpopsure/public-utility';
import { PolicyTemplateData } from 'pages/policies/template/models/PolicyTemplateData';
import { insuranceNameMapper } from 'shared/insurances/insuranceNameMapper';
import { policyDocumentCategories } from 'shared/policyDocumentCategories';

import { NUMBER_OF_POLICIES_PER_PAGE } from '../template/constants';
import { getDocumentSections } from '../template/defaultPolicyDocumentsConfig';
import { TemplateInsuranceType } from '../template/models/InsuranceTypes';
import { getDbInsuranceType } from '../template/utils';
import { getOverviewTabCards } from './data/overviewTabData';
import { getQuestionnaireData } from './data/questionnaireData';
import {
  extendedValidatorObject,
  getLiabilityModals,
  liabilityNextStatuses,
  liabilityStatusOptions,
  retrieveModalId,
} from './data/statusData';
import { rowConfig, tableHeaders } from './data/tableData';
import { tableFilters } from './data/tableFilters';
import { GET_LIABILITY_POLICIES } from './graphql/policies';
import { GET_LIABILITY_POLICY } from './graphql/singlePolicy';
import { UPDATE_LIABILITY_QUESTIONNAIRE } from './graphql/updateQuestionnaire';
import { UPDATE_LIABILITY_POLICY_STATUS } from './graphql/updateStatus';
import { LiabilityPolicyData } from './models/LiabilityPolicy';

export const getLiabilityPoliciesTemplateData =
  (): PolicyTemplateData<LiabilityPolicyData> => {
    const insurancePath = 'liability';
    const insuranceType = getDbInsuranceType(
      insurancePath
    ) as TemplateInsuranceType;
    const insuranceName = insuranceNameMapper[insuranceType];

    return {
      insuranceType,
      navigation: {
        current: `policies-${insurancePath}`,
      },
      queries: {
        allPolicies: GET_LIABILITY_POLICIES,
        policy: GET_LIABILITY_POLICY,
      },
      mutations: {
        statusChange: UPDATE_LIABILITY_POLICY_STATUS,
        editQuestionnaire: UPDATE_LIABILITY_QUESTIONNAIRE,
      },
      copy: {
        table: {
          title: `${insuranceName} policies`,
          searchPlaceholder:
            'Search by name, email, policy number, policy id or utm source',
        },
        details: {
          pageTitle: `${insuranceName} policy - Feather Admin Panel`,
          title: insuranceName,
        },
      },
      features: {
        overviewTable: {
          numberOfRowsPerPage: NUMBER_OF_POLICIES_PER_PAGE,
          headersConfig: tableHeaders,
          filtersConfig: tableFilters,
          rowConfig,
        },
        details: {
          queryVariables: {
            insuranceType,
          },
          insuredPersonName: (data?: LiabilityPolicyData) =>
            capitalizeName({
              firstName: data?.liabilityPolicy.insuredPerson?.firstName ?? '',
              lastName: data?.liabilityPolicy.insuredPerson?.lastName ?? '',
            }) ?? '',
          overviewTab: {
            getCards: getOverviewTabCards,
          },
        },
        statusChange: {
          statusOptions: liabilityStatusOptions,
          statusChangeOptions: liabilityNextStatuses,
          getModals: getLiabilityModals,
          retrieveModalId,
          extendedValidatorObject,
          additionalQueryVariables: { insuranceType },
        },
        documents: {
          uploadOptions: policyDocumentCategories,
          getDocumentSections: (data?: LiabilityPolicyData) =>
            getDocumentSections(data?.liabilityPolicy),
        },
        questionnaire: {
          cardTitle: 'Personal information',
          editable: true,
          getQuestionnaireData,
        },
      },
    };
  };
