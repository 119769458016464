import { Policy } from 'models/policy';
import { User } from 'models/User';

import { CustomerPoliciesView } from './CustomerPolicies.view';
import { orderPoliciesByStatus } from './utils';

interface Props {
  customer?: User;
  policies?: Policy[];
  loading: boolean;
}

export const CustomerPolicies = ({ customer, policies, loading }: Props) => {
  // TODO: [KONG] Move logic to BE
  const statusOrderedPolicies = policies?.length
    ? orderPoliciesByStatus(policies)
    : undefined;

  return (
    <CustomerPoliciesView
      customer={customer}
      loading={loading}
      policies={statusOrderedPolicies}
    />
  );
};
