import { InformationSection } from 'components/InformationCard';
import DateInformation from 'components/InformationCard/DateInformation';
import StatusInformation from 'components/InformationCard/StatusInformation';
import TextInformation from 'components/InformationCard/TextInformation';
import { ClaimAssessment } from 'models/ClaimAssessment';
import { claimAssessmentBadgeColorMapper } from 'shared/claimAssessments/badgeColorMapper';
import { ClaimAssessmentStatus } from 'shared/claimAssessments/models';
import { claimAssessmentStatusNameMapper } from 'shared/claimAssessments/statusNameMapper';

export const retrieveClaimAssessmentInfoRows = (
  claimAssessmentInfo: ClaimAssessment
): InformationSection[] => [
  {
    id: 'claimAssessmentInfo',
    title: 'Assessment Details',
    boldTitle: false,
    rows: [
      {
        id: 'title',
        title: 'Title',
        data: claimAssessmentInfo?.title ?? '',
        type: 'TEXT',
        component: TextInformation,
        editable: true,
      },
      {
        id: 'createdOn',
        title: 'Created on',
        data: claimAssessmentInfo?.createdAt ?? '',
        type: 'DATE',
        component: DateInformation,
        editable: false,
      },
      {
        id: 'status',
        title: 'Status',
        data: claimAssessmentInfo?.status ?? 'CUSTOMER_CONTACTED',
        statusMapping: (status) => {
          return {
            color:
              claimAssessmentBadgeColorMapper[
                status as ClaimAssessmentStatus
              ] ?? 'blue',
            text: {
              text:
                claimAssessmentStatusNameMapper[
                  status as ClaimAssessmentStatus
                ] ?? '',
              id: status,
            },
          };
        },
        type: 'STATUS',
        component: StatusInformation,
        editable: false,
      },
      {
        id: 'doctorName',
        title: "Doctor or clinic's name",
        data: claimAssessmentInfo?.doctorName ?? '',
        type: 'TEXT',
        component: TextInformation,
        editable: true,
      },
      {
        id: 'doctorEmail',
        title: 'Doctor or clinic’s email',
        data: claimAssessmentInfo?.doctorEmail ?? '',
        type: 'TEXT',
        component: TextInformation,
        editable: true,
      },
      {
        id: 'diagnosis',
        title: 'Diagnosis',
        data: claimAssessmentInfo?.diagnosis ?? '',
        type: 'TEXT',
        component: TextInformation,
        editable: true,
      },
    ],
  },
];
