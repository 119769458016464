import { useMutation, useReactiveVar } from '@apollo/client';
import { useTranslation } from '@getpopsure/i18n-react';
import Button from 'components/Button';
import FileDropzone from 'components/FileDropzone';
import Input from 'components/Input';
import Modal from 'components/Modal';
import SelectMenu, { SelectMenuOption } from 'components/SelectMenu';
import { DocumentNode } from 'graphql';
import { useState } from 'react';
import { alertBanners, setNewAlertBanner } from 'shared/reactiveVariables';
import { AlertBannerState } from 'shared/reactiveVariables/models';
import { v4 as uuidv4 } from 'uuid';

export const UploadButton = ({
  mutation,
  getResourceQuery,
  resourceId,
  documentVisibilityOptions,
  displayInternalNotes,
}: {
  mutation: DocumentNode;
  resourceId: string;
  getResourceQuery: DocumentNode;
  documentVisibilityOptions: SelectMenuOption[];
  displayInternalNotes: boolean;
}) => {
  const { t } = useTranslation();
  const alertBannersState = useReactiveVar(alertBanners);
  const [open, setOpen] = useState(false);
  const [updatedDocuments, setUpdatedDocuments] = useState<
    {
      id: string;
      file: File;
      companyId: string;
      uploadType?: string;
      category?: string;
      friendlyName?: string;
      internalNotes?: string;
      isVisibleToHr?: boolean;
    }[]
  >([]);
  const [nameError, setNameError] = useState<Record<string, boolean>>({});

  const [uploadDocument, { loading }] = useMutation(mutation, {
    variables: {
      id: resourceId,
    },
    refetchQueries: [getResourceQuery],
    onCompleted: () => {
      setOpen(false);
      resetForm();
      const newAlertBanner: AlertBannerState = {
        id: uuidv4(),
        type: 'SUCCESS',
        message: t(
          'admin.companyDocuments.upload.success.title',
          'Document(s) were uploaded successfully.'
        ),
      };

      setNewAlertBanner({ state: alertBannersState, newAlertBanner });
    },
    onError: () => {
      setOpen(false);
      resetForm();
      const newAlertBanner: AlertBannerState = {
        id: uuidv4(),
        type: 'WARNING',
        message: t(
          'admin.companyDocuments.upload.error.title',
          'Something went wrong. Please try again.'
        ),
      };

      setNewAlertBanner({ state: alertBannersState, newAlertBanner });
    },
  });

  const resetForm = () => {
    setUpdatedDocuments([]);
  };

  const handleUploadDocument = () => {
    if (!updatedDocuments || updatedDocuments.length === 0) return;

    setNameError({});
    uploadDocument({
      variables: {
        documents: updatedDocuments.map((document) => ({
          companyId: resourceId,
          file: document.file,
          uploadType: 'HR',
          friendlyName: document.friendlyName,
          internalNotes: document.internalNotes,
          isVisibleToHr: document.isVisibleToHr,
        })),
      },
    });
  };

  const handleOpenModal = () => {
    resetForm();
    setOpen(true);
  };

  const getVisibility = (id: string) => {
    const selected: SelectMenuOption[] = [];
    const updatedDocument = updatedDocuments?.find(
      (document) => document.id === id
    );

    if (!updatedDocument) return selected;

    if (updatedDocument?.isVisibleToHr) {
      const hrOption = documentVisibilityOptions?.find(
        (option) => option.id === 'HR'
      );
      if (hrOption) selected.push(hrOption);
    }

    return selected;
  };

  const updateVisibility = (newSelected: SelectMenuOption[], id: string) => {
    const hr = newSelected.find((option) => option.id === 'HR');

    setUpdatedDocuments(
      updatedDocuments.map((document) => {
        if (document.id === id) {
          return {
            ...document,
            isVisibleToHr: !!hr,
          };
        }
        return document;
      })
    );
  };

  const addNewFile = (newFile: File) => {
    const id = uuidv4();
    setUpdatedDocuments([
      ...updatedDocuments,
      {
        id,
        file: newFile,
        isVisibleToHr: true,
        companyId: id,
      },
    ]);
  };

  return (
    <>
      <Button
        type="button"
        className="w-[88px]"
        onClick={() => handleOpenModal()}
        buttonType="secondary"
      >
        {t('admin.companyDocuments.action.upload.label', 'Upload')}
      </Button>
      <Modal
        title={t('admin.companyDocuments.uploadModal.title', 'Upload document')}
        open={open}
        setOpen={setOpen}
        confirmButtonLabel={t(
          'admin.companyDocuments.uploadModal.confirm.label',
          'Confirm'
        )}
        loading={loading}
        handleConfirm={handleUploadDocument}
        disabled={!updatedDocuments || updatedDocuments.length === 0}
        scrollable
      >
        <div className="flex flex-col space-y-4">
          {updatedDocuments.map((updatedDocument) => {
            return (
              <>
                <FileDropzone
                  uploadedFile={updatedDocument.file}
                  onFileSelect={() => {}}
                  onFileDelete={() =>
                    setUpdatedDocuments(
                      updatedDocuments.filter(
                        (d) => d.id !== updatedDocument.id
                      )
                    )
                  }
                  color="gray"
                />
                <>
                  <div className="flex items-center justify-between">
                    <label className="text-sm font-bold" htmlFor="name">
                      {t(
                        'admin.companyDocuments.uploadModal.name.label',
                        'Name'
                      )}
                    </label>
                    <Input
                      id="name"
                      color="gray"
                      className="w-[288px]"
                      placeholder={t(
                        'admin.companyDocuments.uploadModal.name.placeholder',
                        'User-friendly name'
                      )}
                      value={updatedDocument?.friendlyName}
                      onChange={(e) =>
                        setUpdatedDocuments(
                          updatedDocuments.map((document) => {
                            if (document.id === updatedDocument.id) {
                              return {
                                ...document,
                                friendlyName: e.target.value,
                              };
                            }
                            return document;
                          })
                        )
                      }
                      error={nameError[updatedDocument.id]}
                      errorMessage={t(
                        'admin.companyDocuments.uploadModal.error.label',
                        'This field is required'
                      )}
                    />
                  </div>
                  {documentVisibilityOptions && (
                    <div>
                      <SelectMenu
                        color="gray"
                        options={documentVisibilityOptions}
                        selected={getVisibility(updatedDocument.id)}
                        setSelected={(newSelected: SelectMenuOption[]) =>
                          updateVisibility(newSelected, updatedDocument.id)
                        }
                        placeholder={t(
                          'admin.companyDocuments.uploadModal.visibility.placeholder',
                          'Visibility option(s)'
                        )}
                        label={t(
                          'admin.companyDocuments.uploadModal.visibility.label',
                          'Visibility'
                        )}
                        horizontal
                        className="w-[288px]"
                        multiple
                      />
                    </div>
                  )}
                  {displayInternalNotes && (
                    <div className="flex items-center justify-between">
                      <label className="text-sm font-bold" htmlFor="notes">
                        {t(
                          'admin.companyDocuments.uploadModal.notes.label',
                          'Notes'
                        )}
                      </label>
                      <Input
                        id="notes"
                        color="gray"
                        className="w-[288px]"
                        placeholder={t(
                          'admin.companyDocuments.uploadModal.notes.placeholder',
                          'Internal notes'
                        )}
                        value={updatedDocument?.internalNotes}
                        onChange={(e) =>
                          setUpdatedDocuments(
                            updatedDocuments.map((document) => {
                              if (document.id === updatedDocument.id) {
                                return {
                                  ...document,
                                  internalNotes: e.target.value,
                                };
                              }
                              return document;
                            })
                          )
                        }
                      />
                    </div>
                  )}
                </>
                <div className="mt-[20px] w-full border-t border-gray-300" />
              </>
            );
          })}
          <FileDropzone
            uploadedFile={undefined}
            onFileSelect={(newFile) => newFile && addNewFile(newFile)}
            onFileDelete={() => setUpdatedDocuments([])}
            color="gray"
          />
        </div>
      </Modal>
    </>
  );
};
