import { BadgeColor } from 'components/Badge/Badge';
import { badgeColorMapper as bikeClaimsBadgeColorMapper } from 'shared/claims/bike/badgeColorMapper';
import { claimTypeNameMapper as bikeClaimTypeNameMapper } from 'shared/claims/bike/claimTypeNameMapper';
import { statusNameMapper as bikeClaimsStatusNameMapper } from 'shared/claims/bike/statusNameMapper';
import { badgeColorMapper as dentalClaimsBadgeColorMapper } from 'shared/claims/dental/badgeColorMapper';
import { claimTypeNameMapper as dentalClaimTypeNameMapper } from 'shared/claims/dental/claimTypeNameMapper';
import { statusNameMapper as dentalClaimsStatusNameMapper } from 'shared/claims/dental/statusNameMapper';
import { badgeColorMapper as dogLiabilityClaimsBadgeColorMapper } from 'shared/claims/dogLiability/badgeColorMapper';
import { claimTypeNameMapper as dogLiabilityClaimTypeNameMapper } from 'shared/claims/dogLiability/claimTypeNameMapper';
import { statusNameMapper as dogLiabilityClaimsStatusNameMapper } from 'shared/claims/dogLiability/statusNameMapper';
import { badgeColorMapper as expatHealthClaimsBadgeColorMapper } from 'shared/claims/expatHealth/badgeColorMapper';
import { claimTypeNameMapper as expatClaimTypeNameMapper } from 'shared/claims/expatHealth/claimTypeNameMapper';
import { statusNameMapper as expatHealthClaimsStatusNameMapper } from 'shared/claims/expatHealth/statusNameMapper';
import { badgeColorMapper as expatSpainClaimsBadgeColorMapper } from 'shared/claims/expatSpain/badgeColorMapper';
import { claimTypeNameMapper as expatSpainTypeNameMapper } from 'shared/claims/expatSpain/claimTypeNameMapper';
import { statusNameMapper as expatSpainClaimsStatusNameMapper } from 'shared/claims/expatSpain/statusNameMapper';
import { badgeColorMapper as genericClaimsBadgeColorMapper } from 'shared/claims/generic/badgeColorMapper';
import { claimTypeNameMapper as genericClaimTypeNameMapper } from 'shared/claims/generic/claimTypeNameMapper';
import { statusNameMapper as genericClaimsStatusNameMapper } from 'shared/claims/generic/statusNameMapper';
import { badgeColorMapper as householdClaimsBadgeColorMapper } from 'shared/claims/household/badgeColorMapper';
import { claimTypeNameMapper as householdClaimTypeNameMapper } from 'shared/claims/household/claimTypeNameMapper';
import { statusNameMapper as householdClaimsStatusNameMapper } from 'shared/claims/household/statusNameMapper';
import { badgeColorMapper as legalClaimsBadgeColorMapper } from 'shared/claims/legal/badgeColorMapper';
import { claimTypeNameMapper as legalClaimTypeNameMapper } from 'shared/claims/legal/claimTypeNameMapper';
import { statusNameMapper as legalClaimsStatusNameMapper } from 'shared/claims/legal/statusNameMapper';
import { badgeColorMapper as liabilityClaimsBadgeColorMapper } from 'shared/claims/liability/badgeColorMapper';
import { claimTypeNameMapper as liabilityClaimTypeNameMapper } from 'shared/claims/liability/claimTypeNameMapper';
import { statusNameMapper as liabilityClaimsStatusNameMapper } from 'shared/claims/liability/statusNameMapper';
import { badgeColorMapper as privateHealthClaimsBadgeColorMapper } from 'shared/claims/privateHealth/badgeColorMapper';
import { claimTypeNameMapper as privateHealthClaimTypeNameMapper } from 'shared/claims/privateHealth/claimTypeNameMapper';
import { statusNameMapper as privateHealthClaimsStatusNameMapper } from 'shared/claims/privateHealth/statusNameMapper';
import { Country } from 'shared/countries/models';

import { insuranceIconMapper } from './insuranceIconMapper';
import { insuranceNameMapper } from './insuranceNameMapper';
import { policyRouteMapper } from './policyRouteMapper';
import { bikeBadgeColorMapper, bikeStatusMapper } from './statusMappers/bike';
import {
  dentalBadgeColorMapper,
  dentalStatusMapper,
} from './statusMappers/dental';
import {
  disabilityBadgeColorMapper,
  disabilityStatusMapper,
} from './statusMappers/disability';
import {
  dogLiabilityBadgeColorMapper,
  dogLiabilityStatusMapper,
} from './statusMappers/dogLiability';
import {
  expatBadgeColorMapper,
  expatStatusMapper,
} from './statusMappers/expat';
import {
  expatSpainBadgeColorMapper,
  expatSpainStatusMapper,
} from './statusMappers/expatSpain';
import {
  genericBadgeColorMapper,
  genericStatusMapper,
} from './statusMappers/generic';
import {
  householdBadgeColorMapper,
  householdStatusMapper,
} from './statusMappers/household';
import {
  legalBadgeColorMapper,
  legalStatusMapper,
} from './statusMappers/legal';
import {
  liabilityBadgeColorMapper,
  liabilityStatusMapper,
} from './statusMappers/liability';
import { lifeBadgeColorMapper, lifeStatusMapper } from './statusMappers/life';
import {
  privateHealthBadgeColorMapper,
  privateHealthStatusMapper,
} from './statusMappers/privateHealth';
import {
  publicHealthBadgeColorMapper,
  publicHealthStatusMapper,
} from './statusMappers/publicHealth';
import { InsuranceType } from './types';

export interface Mappers {
  statusMapper: Record<string, string>;
  badgeColorMapper: Record<string, BadgeColor>;
  name: string;
  icon: string;
  path: (policyId: string, region?: Country) => string;
  claim?: {
    claimStatusMapper: Record<string, string>;
    badgeColorMapper: Record<string, BadgeColor>;
    typeNameMapper: Record<string, string>;
  };
}

export const policyMappers: Record<InsuranceType, Mappers | null> = {
  BIKE: {
    statusMapper: bikeStatusMapper,
    badgeColorMapper: bikeBadgeColorMapper,
    name: insuranceNameMapper.BIKE,
    icon: insuranceIconMapper.BIKE,
    path: policyRouteMapper.BIKE,
    claim: {
      claimStatusMapper: bikeClaimsStatusNameMapper,
      badgeColorMapper: bikeClaimsBadgeColorMapper,
      typeNameMapper: bikeClaimTypeNameMapper,
    },
  },
  DENTAL: {
    statusMapper: dentalStatusMapper,
    badgeColorMapper: dentalBadgeColorMapper,
    name: insuranceNameMapper.DENTAL,
    icon: insuranceIconMapper.DENTAL,
    path: policyRouteMapper.DENTAL,
    claim: {
      claimStatusMapper: dentalClaimsStatusNameMapper,
      badgeColorMapper: dentalClaimsBadgeColorMapper,
      typeNameMapper: dentalClaimTypeNameMapper,
    },
  },
  DISABILITY: {
    statusMapper: disabilityStatusMapper,
    badgeColorMapper: disabilityBadgeColorMapper,
    name: insuranceNameMapper.DISABILITY,
    icon: insuranceIconMapper.DISABILITY,
    path: policyRouteMapper.DISABILITY,
  },
  INCOMING: {
    statusMapper: expatStatusMapper,
    badgeColorMapper: expatBadgeColorMapper,
    name: insuranceNameMapper.INCOMING,
    icon: insuranceIconMapper.INCOMING,
    path: policyRouteMapper.INCOMING,
    claim: {
      claimStatusMapper: expatHealthClaimsStatusNameMapper,
      badgeColorMapper: expatHealthClaimsBadgeColorMapper,
      typeNameMapper: expatClaimTypeNameMapper,
    },
  },
  HOUSEHOLD: {
    statusMapper: householdStatusMapper,
    badgeColorMapper: householdBadgeColorMapper,
    name: insuranceNameMapper.HOUSEHOLD,
    icon: insuranceIconMapper.HOUSEHOLD,
    path: policyRouteMapper.HOUSEHOLD,
    claim: {
      claimStatusMapper: householdClaimsStatusNameMapper,
      badgeColorMapper: householdClaimsBadgeColorMapper,
      typeNameMapper: householdClaimTypeNameMapper,
    },
  },
  LEGAL: {
    statusMapper: legalStatusMapper,
    badgeColorMapper: legalBadgeColorMapper,
    name: insuranceNameMapper.LEGAL,
    icon: insuranceIconMapper.LEGAL,
    path: policyRouteMapper.LEGAL,
    claim: {
      claimStatusMapper: legalClaimsStatusNameMapper,
      badgeColorMapper: legalClaimsBadgeColorMapper,
      typeNameMapper: legalClaimTypeNameMapper,
    },
  },
  LIABILITY: {
    statusMapper: liabilityStatusMapper,
    badgeColorMapper: liabilityBadgeColorMapper,
    name: insuranceNameMapper.LIABILITY,
    icon: insuranceIconMapper.LIABILITY,
    path: policyRouteMapper.LIABILITY,
    claim: {
      claimStatusMapper: liabilityClaimsStatusNameMapper,
      badgeColorMapper: liabilityClaimsBadgeColorMapper,
      typeNameMapper: liabilityClaimTypeNameMapper,
    },
  },
  LIFE: {
    statusMapper: lifeStatusMapper,
    badgeColorMapper: lifeBadgeColorMapper,
    name: insuranceNameMapper.LIFE,
    icon: insuranceIconMapper.LIFE,
    path: policyRouteMapper.LIFE,
  },
  PUBLIC_HEALTH: {
    statusMapper: publicHealthStatusMapper,
    badgeColorMapper: publicHealthBadgeColorMapper,
    name: insuranceNameMapper.PUBLIC_HEALTH,
    icon: insuranceIconMapper.PUBLIC_HEALTH,
    path: policyRouteMapper.PUBLIC_HEALTH,
  },
  PRIVATE_HEALTH: {
    statusMapper: privateHealthStatusMapper,
    badgeColorMapper: privateHealthBadgeColorMapper,
    name: insuranceNameMapper.PRIVATE_HEALTH,
    icon: insuranceIconMapper.PRIVATE_HEALTH,
    path: policyRouteMapper.PRIVATE_HEALTH,
    claim: {
      claimStatusMapper: privateHealthClaimsStatusNameMapper,
      badgeColorMapper: privateHealthClaimsBadgeColorMapper,
      typeNameMapper: privateHealthClaimTypeNameMapper,
    },
  },
  DOG_LIABILITY: {
    statusMapper: dogLiabilityStatusMapper,
    badgeColorMapper: dogLiabilityBadgeColorMapper,
    name: insuranceNameMapper.DOG_LIABILITY,
    icon: insuranceIconMapper.DOG_LIABILITY,
    path: policyRouteMapper.DOG_LIABILITY,
    claim: {
      claimStatusMapper: dogLiabilityClaimsStatusNameMapper,
      badgeColorMapper: dogLiabilityClaimsBadgeColorMapper,
      typeNameMapper: dogLiabilityClaimTypeNameMapper,
    },
  },
  GENERIC: {
    statusMapper: genericStatusMapper,
    badgeColorMapper: genericBadgeColorMapper,
    name: insuranceNameMapper.GENERIC,
    icon: insuranceIconMapper.GENERIC,
    path: policyRouteMapper.GENERIC,
    claim: {
      claimStatusMapper: genericClaimsStatusNameMapper,
      badgeColorMapper: genericClaimsBadgeColorMapper,
      typeNameMapper: genericClaimTypeNameMapper,
    },
  },
  TRAVEL: {
    statusMapper: genericStatusMapper,
    badgeColorMapper: genericBadgeColorMapper,
    name: insuranceNameMapper.TRAVEL,
    icon: insuranceIconMapper.TRAVEL,
    path: policyRouteMapper.TRAVEL,
  },
  CAR: {
    statusMapper: genericStatusMapper,
    badgeColorMapper: genericBadgeColorMapper,
    name: insuranceNameMapper.CAR,
    icon: insuranceIconMapper.CAR,
    path: policyRouteMapper.CAR,
  },
  PET_HEALTH: {
    statusMapper: genericStatusMapper,
    badgeColorMapper: genericBadgeColorMapper,
    name: insuranceNameMapper.PET_HEALTH,
    icon: insuranceIconMapper.PET_HEALTH,
    path: policyRouteMapper.PET_HEALTH,
  },
  COMPANY_HEALTH: {
    statusMapper: genericStatusMapper,
    badgeColorMapper: genericBadgeColorMapper,
    name: insuranceNameMapper.COMPANY_HEALTH,
    icon: insuranceIconMapper.COMPANY_HEALTH,
    path: policyRouteMapper.COMPANY_HEALTH,
  },
  COMPANY_LIFE: {
    statusMapper: genericStatusMapper,
    badgeColorMapper: genericBadgeColorMapper,
    name: insuranceNameMapper.COMPANY_LIFE,
    icon: insuranceIconMapper.COMPANY_LIFE,
    path: policyRouteMapper.COMPANY_LIFE,
  },
  PENSION: {
    statusMapper: genericStatusMapper,
    badgeColorMapper: genericBadgeColorMapper,
    name: insuranceNameMapper.PENSION,
    icon: insuranceIconMapper.PENSION,
    path: policyRouteMapper.PENSION,
  },
  BASIS_PENSION: {
    statusMapper: genericStatusMapper,
    badgeColorMapper: genericBadgeColorMapper,
    name: insuranceNameMapper.BASIS_PENSION,
    icon: insuranceIconMapper.BASIS_PENSION,
    path: policyRouteMapper.BASIS_PENSION,
  },
  INCOMING_ES: {
    statusMapper: expatSpainStatusMapper,
    badgeColorMapper: expatSpainBadgeColorMapper,
    name: insuranceNameMapper.INCOMING_ES,
    icon: insuranceIconMapper.INCOMING_ES,
    path: policyRouteMapper.INCOMING_ES,
    claim: {
      claimStatusMapper: expatSpainClaimsStatusNameMapper,
      badgeColorMapper: expatSpainClaimsBadgeColorMapper,
      typeNameMapper: expatSpainTypeNameMapper,
    },
  },
  TRAVEL_FR: {
    statusMapper: genericStatusMapper,
    badgeColorMapper: genericBadgeColorMapper,
    name: insuranceNameMapper.TRAVEL_FR,
    icon: insuranceIconMapper.TRAVEL_FR,
    path: policyRouteMapper.TRAVEL_FR,
  },
  JOB: null,
  INCOMING_EU: {
    statusMapper: genericStatusMapper,
    badgeColorMapper: genericBadgeColorMapper,
    name: insuranceNameMapper.INCOMING_EU,
    icon: insuranceIconMapper.INCOMING_EU,
    path: policyRouteMapper.INCOMING_EU,
  },
  INCOMING_LT: {
    statusMapper: genericStatusMapper,
    badgeColorMapper: genericBadgeColorMapper,
    name: insuranceNameMapper.INCOMING_LT,
    icon: insuranceIconMapper.INCOMING_LT,
    path: policyRouteMapper.INCOMING_LT,
  },
  COMPANY_PENSION: {
    statusMapper: genericStatusMapper,
    badgeColorMapper: genericBadgeColorMapper,
    name: insuranceNameMapper.COMPANY_PENSION,
    icon: insuranceIconMapper.COMPANY_PENSION,
    path: policyRouteMapper.COMPANY_PENSION,
  },
  DOG_LIABILITY_ES: {
    statusMapper: genericStatusMapper,
    badgeColorMapper: genericBadgeColorMapper,
    name: insuranceNameMapper.DOG_LIABILITY_ES,
    icon: insuranceIconMapper.DOG_LIABILITY_ES,
    path: policyRouteMapper.DOG_LIABILITY_ES,
  },
  BIKE_ES: {
    statusMapper: genericStatusMapper,
    badgeColorMapper: genericBadgeColorMapper,
    name: insuranceNameMapper.BIKE_ES,
    icon: insuranceIconMapper.BIKE_ES,
    path: policyRouteMapper.BIKE_ES,
  },
  DENTAL_ES: {
    statusMapper: genericStatusMapper,
    badgeColorMapper: genericBadgeColorMapper,
    name: insuranceNameMapper.DENTAL_ES,
    icon: insuranceIconMapper.DENTAL_ES,
    path: policyRouteMapper.DENTAL_ES,
  },
  LIFE_EU: {
    statusMapper: genericStatusMapper,
    badgeColorMapper: genericBadgeColorMapper,
    name: insuranceNameMapper.LIFE_EU,
    icon: insuranceIconMapper.LIFE_EU,
    path: policyRouteMapper.LIFE_EU,
  },
};
