import { DocumentNode, useMutation } from '@apollo/client';
import { useTranslation } from '@getpopsure/i18n-react';
import { PlusIcon } from '@heroicons/react/solid';
import Button from 'components/Button';
import { TaskTemplateSchema } from 'pages/admin/taskTemplates/components/taskTemplate.types';
import {
  CREATE_TASK_TEMPLATE,
  UPDATE_TASK_TEMPLATE,
} from 'pages/admin/taskTemplates/graphql/mutations';
import { useState } from 'react';
import { useLocation } from 'react-router';
import { InsuranceType } from 'shared/insurances/types';
import type { IssueStatusIncludingExpiredType } from 'shared/mapIssueStatus';

import { CreateTaskForm } from '../CreateTaskForm/CreateTaskForm';
import { TaskCard } from '../TaskCard/TaskCard';
import {
  CreateTaskPayload,
  Task,
  UpdateTaskPayload,
} from '../TaskEngine.types';
import { TaskPreviewModal } from '../TaskPreviewModal/TaskPreviewModal';
import {
  getTaskPayload,
  getTaskTemplatePayload,
} from '../utils/getTaskPayload';
import { CREATE_TASK, UPDATE_TASK } from './TaskList.mutations';
import * as styles from './TaskList.styles';

interface IssueTasksProps {
  issueStatus: IssueStatusIncludingExpiredType;
  issueId: string;
  userId: string;
  policyOrClaimId: string;
  tasks?: Task[];
  refetchQueries?: DocumentNode[];
  taskContext?: string;
  insuranceType: InsuranceType;
}

type Payload =
  | ReturnType<typeof getTaskPayload>
  | {
      task: ReturnType<typeof getTaskPayload>;
      template: ReturnType<typeof getTaskTemplatePayload>;
    };

export const TaskList = ({
  issueId,
  userId,
  tasks,
  refetchQueries,
  issueStatus,
  policyOrClaimId,
  taskContext,
  insuranceType,
}: IssueTasksProps) => {
  const [openCreateForm, setOpenCreateForm] = useState(false);
  const [selectedTask, setSelectedTask] = useState<Task | null>(null);
  const { t } = useTranslation();
  const [createTask, { loading: createTaskLoading, error: createTaskError }] =
    useMutation<unknown, CreateTaskPayload>(CREATE_TASK);
  const [
    createTaskTemplateMutation,
    { loading: createTaskTemplateLoading, error: createTaskTemplateError },
  ] = useMutation(CREATE_TASK_TEMPLATE);
  const [
    updateTaskTemplateMutation,
    { loading: updateTaskTemplateLoading, error: updateTaskTemplateError },
  ] = useMutation(UPDATE_TASK_TEMPLATE);
  const [updateTask, { loading: updateTaskLoading, error: updateTaskError }] =
    useMutation<unknown, UpdateTaskPayload>(UPDATE_TASK);
  const location = useLocation();

  const loading =
    createTaskLoading ||
    createTaskTemplateLoading ||
    updateTaskTemplateLoading ||
    updateTaskLoading;
  const error =
    createTaskError ||
    createTaskTemplateError ||
    updateTaskTemplateError ||
    updateTaskError;

  const handleOnSubmit = (payload: Payload) => {
    if ('template' in payload) {
      const { task, template: templatePayload } = payload;
      const { template, createTaskTemplate, updateTaskTemplate } =
        templatePayload;

      const {
        metadata: { taskTemplateId, ...taskMetaData },
      } = task;

      createTask({
        variables: {
          input: {
            userId,
            issueId,
            description: {
              type: 'GENERIC',
              ...task,
              metadata: { ...taskMetaData, subtitle: taskContext },
              attributes: {
                taskTemplateId,
                insuranceType,
                ...(location.pathname.includes('claims')
                  ? { claimId: policyOrClaimId }
                  : { policyId: policyOrClaimId }),
              },
            },
          },
        },
        refetchQueries,
        onCompleted: () => setOpenCreateForm(false),
      });

      if (createTaskTemplate) {
        createTaskTemplateMutation({
          variables: {
            input: { ...template },
          },
        });
      }

      if (updateTaskTemplate && template?.id) {
        updateTaskTemplateMutation({
          variables: {
            id: template.id,
            input: {
              ...TaskTemplateSchema.omit({ id: true, name: true }).parse(
                template
              ),
            },
          },
        });
      }
    } else {
      createTask({
        variables: {
          input: {
            userId,
            issueId,
            description: {
              type: 'GENERIC',
              ...payload,
            },
          },
        },
        refetchQueries,
        onCompleted: () => setOpenCreateForm(false),
      });
    }
  };

  const handleOpenTask = (task: Task) => () => setSelectedTask(task);

  const handleCancelTask = (taskId: string) => () => {
    updateTask({
      variables: {
        id: taskId,
        input: {
          status: 'CANCELED',
        },
      },
      refetchQueries,
    });
  };

  return (
    <>
      <div className={styles.container}>
        {issueStatus !== 'RESOLVED' && (
          <Button
            buttonType="white"
            className={styles.button}
            onClick={() => setOpenCreateForm(true)}
          >
            <PlusIcon className={styles.plusIcon} />
            {t('issuesTab.issueTasks.createTask', 'New task')}
          </Button>
        )}
        <div className={styles.taskWrapper}>
          {tasks?.map((task) => (
            <TaskCard
              key={task.id}
              task={task}
              onClickTask={handleOpenTask(task)}
              onCancelTask={handleCancelTask(task.id)}
            />
          ))}
        </div>
      </div>
      {openCreateForm && (
        <CreateTaskForm
          setOpen={setOpenCreateForm}
          onSubmit={handleOnSubmit}
          loading={loading}
          error={error}
          insuranceType={insuranceType}
        />
      )}

      {selectedTask && (
        <TaskPreviewModal
          task={selectedTask}
          onClose={() => setSelectedTask(null)}
        />
      )}
    </>
  );
};
