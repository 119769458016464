import { BadgeColor } from 'components/Badge/Badge';

import { ExpatClaimStatus } from './models';

export const badgeColorMapper: Record<ExpatClaimStatus, BadgeColor> = {
  RECEIVED: 'purple',
  SUBMITTED_TO_PROVIDER: 'blue',
  APPROVED_WITH_OTHER_CLAIMS: 'green',
  DUPLICATE: 'gray',
  INCOMPLETE: 'red',
  CUSTOMER_PAID_OUT: 'green',
  OTHER_PAID_OUT: 'green',
  DENIED_WITH_OTHER_CLAIMS: 'yellow',
  DENIED: 'yellow',
  FEATHER_PAID_OUT: 'gray',
  CLOSED: 'gray',
  MISSING_INFO_RECEIVED: 'gray',
  PROCESSED_WITH_OTHER_CLAIM: 'gray',
  MISSING_PAYMENT_INFO: 'gray',
  DROPPED_OUT: 'gray',
};
