import { useMutation } from '@apollo/client';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import Button from 'components/Button/Button';
import { NumberInput } from 'components/Input/NumberInput';
import { TariffInfo } from 'models/tariffInfo';
import { UPDATE_PRICE_INFORMATION } from 'pages/policies/privateHealth/graphql/mutations';
import { getAddon } from 'pages/policies/privateHealth/utils/mapAddons';
import { ChangeEvent, useState } from 'react';

interface PriceDetailsProps {
  tariffInfo?: TariffInfo;
  occupation: string;
  policyId: string;
}

const PriceDetails = ({
  tariffInfo,
  occupation,
  policyId,
}: PriceDetailsProps) => {
  const [updatePrice, { loading, reset }] = useMutation(
    UPDATE_PRICE_INFORMATION
  );

  const [editMode, setEditMode] = useState(false);
  const [prices, setPrices] = useState({
    ...tariffInfo,
    riskSurchargeAmount: tariffInfo?.riskFactorSurcharge ?? 0,
  });

  const handlePriceChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPrices({ ...prices, [e.target.id]: Number(e.target.value) });
  };

  const handleAddOnPriceChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newAddOnPrices = prices.addOns?.map((addOn) => {
      if (addOn.add_on === e.target.id) {
        return {
          add_on: e.target.id,
          monthly_price: Number(e.target.value),
        };
      }

      return addOn;
    });

    setPrices({
      ...prices,
      addOns: newAddOnPrices,
    });
  };

  const handleDiscard = () => {
    setEditMode(false);
    reset();
    setPrices({
      ...tariffInfo,
      riskSurchargeAmount: tariffInfo?.riskFactorSurcharge ?? 0,
    });
  };

  const handleSave = () => {
    updatePrice({
      variables: {
        addOnsPrice: prices?.addOns,
        noClaimsBonus: prices?.noClaimsBonus ?? 0,
        yearlyCashback: prices?.guaranteedYearlyBonus ?? 0,
        taxDeductibleAmount: prices?.taxDeductibleAmount ?? 0,
        riskSurcharge: prices?.riskSurchargeAmount ?? 0,
        tariffPrice: prices?.tariffMonthlyPrice ?? 0,
        longTermCare: prices?.longTermCare ?? 0,
        statutorySurcharge: prices?.statutorySurcharge ?? 0,
        ownContributions: prices?.ownContributions ?? 0,
        employerContributions: prices?.employerContributions ?? 0,
        policyId,
      },
    })
      .then(() => {
        setEditMode(false);
      })
      .catch(() => {});
  };

  return (
    <>
      <div className="mt-2 flex-col space-y-4 text-sm text-gray-900">
        <div className="flex items-center justify-between">
          <span>Tariff monthly price</span>
          {editMode ? (
            <NumberInput
              color="gray"
              className="w-[200px]"
              value={prices?.tariffMonthlyPrice}
              id="tariffMonthlyPrice"
              onChange={handlePriceChange}
              placeholder="0.00"
              prefix="€"
            />
          ) : (
            <span>
              {englishFormattedEuroCurrency(
                tariffInfo?.tariffMonthlyPrice ?? 0
              )}
            </span>
          )}
        </div>
        <div className="flex-col space-y-2">
          <span>Add-ons</span>
          {tariffInfo?.addOns?.map((addOn) => {
            return (
              <div
                className="flex items-center justify-between text-gray-600"
                key={addOn.add_on}
              >
                <span>
                  {getAddon(addOn.add_on)}{' '}
                  {addOn.add_on === 'KT'
                    ? `${englishFormattedEuroCurrency(
                        tariffInfo.sickDayPayout ?? 0
                      )}`
                    : ''}
                </span>
                {editMode ? (
                  <NumberInput
                    color="gray"
                    className="w-[200px]"
                    value={
                      prices?.addOns?.find(
                        (priceAddOn) => addOn.add_on === priceAddOn.add_on
                      )?.monthly_price ?? 0
                    }
                    id={addOn.add_on}
                    onChange={handleAddOnPriceChange}
                    placeholder="0.00"
                    prefix="€"
                  />
                ) : (
                  <span>
                    {englishFormattedEuroCurrency(addOn.monthly_price ?? 0)}
                  </span>
                )}
              </div>
            );
          })}
        </div>
        <div className="flex items-center justify-between">
          <span>Risk surcharge (total)</span>
          {editMode ? (
            <NumberInput
              color="gray"
              className="w-[200px]"
              value={prices?.riskSurchargeAmount}
              id="riskSurchargeAmount"
              onChange={handlePriceChange}
              placeholder="0.00"
              prefix="€"
            />
          ) : (
            <span>
              {englishFormattedEuroCurrency(
                tariffInfo?.riskFactorSurcharge ?? 0
              )}
            </span>
          )}
        </div>
        <div className="flex items-center justify-between">
          <span>Statutory surcharge (10%)</span>
          {editMode ? (
            <NumberInput
              color="gray"
              className="w-[200px]"
              value={prices?.statutorySurcharge}
              id="statutorySurcharge"
              onChange={handlePriceChange}
              placeholder="0.00"
              prefix="€"
            />
          ) : (
            <span>
              {englishFormattedEuroCurrency(
                tariffInfo?.statutorySurcharge ?? 0
              )}
            </span>
          )}
        </div>
        <div className="flex items-center justify-between">
          <span>Long term care insurance</span>
          {editMode ? (
            <NumberInput
              color="gray"
              className="w-[200px]"
              value={prices?.longTermCare}
              id="longTermCare"
              onChange={handlePriceChange}
              placeholder="0.00"
              prefix="€"
            />
          ) : (
            <span>
              {englishFormattedEuroCurrency(tariffInfo?.longTermCare ?? 0)}
            </span>
          )}
        </div>
        <div className="w-full border-b border-solid border-gray-300" />
        <div className="flex items-center justify-between font-bold">
          <span>Total monthly price</span>
          <span>
            {englishFormattedEuroCurrency(tariffInfo?.totalMonthlyPrice ?? 0)}
          </span>
        </div>
        {occupation === 'EMPLOYED' && (
          <>
            <div className="flex items-center justify-between">
              <span>Employer contribution</span>
              {editMode ? (
                <NumberInput
                  color="gray"
                  className="w-[200px]"
                  value={prices?.employerContributions}
                  id="employerContributions"
                  onChange={handlePriceChange}
                  placeholder="0.00"
                  prefix="€"
                />
              ) : (
                <span>
                  {englishFormattedEuroCurrency(
                    tariffInfo?.employerContributions ?? 0
                  )}
                </span>
              )}
            </div>
            <div className="flex items-center justify-between">
              <span>Individual contribution</span>
              {editMode ? (
                <NumberInput
                  color="gray"
                  className="w-[200px]"
                  value={prices?.ownContributions}
                  id="ownContributions"
                  onChange={handlePriceChange}
                  placeholder="0.00"
                  prefix="€"
                />
              ) : (
                <span>
                  {englishFormattedEuroCurrency(
                    tariffInfo?.ownContributions ?? 0
                  )}
                </span>
              )}
            </div>
          </>
        )}
        <div className="w-full border-b border-solid border-gray-300" />
        <div className="flex items-center justify-between text-gray-600">
          <span>Tax deductible amount</span>
          {editMode ? (
            <NumberInput
              color="gray"
              className="w-[200px]"
              value={prices?.taxDeductibleAmount}
              id="taxDeductibleAmount"
              onChange={handlePriceChange}
              placeholder="0.00"
              prefix="€"
            />
          ) : (
            <span>
              {englishFormattedEuroCurrency(
                tariffInfo?.taxDeductibleAmount ?? 0
              )}
            </span>
          )}
        </div>
        <div className="flex items-center justify-between text-gray-600">
          <span>Yearly cashback</span>
          {editMode ? (
            <NumberInput
              color="gray"
              className="w-[200px]"
              value={prices?.guaranteedYearlyBonus}
              id="guaranteedYearlyBonus"
              onChange={handlePriceChange}
              placeholder="0.00"
              prefix="€"
            />
          ) : (
            <span>
              {englishFormattedEuroCurrency(
                tariffInfo?.guaranteedYearlyBonus ?? 0
              )}
            </span>
          )}
        </div>
        <div className="flex items-center justify-between text-gray-600">
          <span>No claims bonus</span>
          {editMode ? (
            <NumberInput
              color="gray"
              className="w-[200px]"
              value={prices?.noClaimsBonus}
              id="noClaimsBonus"
              onChange={handlePriceChange}
              placeholder="0.00"
              prefix="€"
            />
          ) : (
            <span>
              {englishFormattedEuroCurrency(tariffInfo?.noClaimsBonus ?? 0)}
            </span>
          )}
        </div>
      </div>
      {occupation === 'EMPLOYED' && editMode && (
        <span className="mt-[10px] inline-block text-sm text-gray-600">
          Letter for employer will be regenerated after saving changes.
        </span>
      )}
      <div className="mt-4 flex justify-end">
        {editMode ? (
          <div className="flex space-x-2">
            <Button buttonType="secondary" onClick={handleDiscard}>
              Discard
            </Button>
            <Button
              buttonType="primary"
              className="w-[140px]"
              onClick={handleSave}
              loading={loading}
            >
              Save
            </Button>
          </div>
        ) : (
          <Button
            buttonType="primary"
            className="w-[140px]"
            onClick={() => setEditMode(true)}
          >
            Edit
          </Button>
        )}
      </div>
    </>
  );
};

export default PriceDetails;
