import classNames from 'classnames';
import { ButtonType } from 'components/Button/Button';
import Loader from 'components/Loader';
import React from 'react';
import { Link } from 'react-router-dom';

/**
 * This component is sharing styles with button styles
 */
import * as styles from '../Button/styles';

interface Props {
  className?: string;
  buttonType: ButtonType;
  loading?: boolean;
  loadingText?: string;
  Icon?: React.ComponentType<any>;
  href: string;
  disabled?: boolean;
  children: React.ReactNode;
  isExternal?: boolean;
  download?: string;
}

export const LinkButton = ({
  buttonType,
  className,
  loading = false,
  loadingText,
  Icon,
  href,
  disabled,
  children,
  isExternal,
  download,
}: Props) => {
  if (isExternal) {
    return (
      <a
        className={classNames(className, styles.button, styles.linkButton, {
          [styles.primary]: buttonType === 'primary',
          [styles.secondary]: buttonType === 'secondary',
          [styles.white]: buttonType === 'white',
          [styles.transparent]: buttonType === 'transparent',
          [styles.disabled]: disabled || loading,
        })}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        download={download}
      >
        <span className={styles.confirmButtonContent}>
          {loading && (
            <Loader className={styles.loader} primaryColor="stroke-white" />
          )}
          {Icon && (
            <Icon
              className={classNames(className, styles.linkButtonIcon, {
                [styles.iconPrimary]: buttonType === 'primary',
                [styles.iconSecondary]: buttonType === 'secondary',
                [styles.iconWhite]: buttonType === 'white',
              })}
            />
          )}
          {loading ? loadingText ?? children : children}
        </span>
      </a>
    );
  }

  return (
    <Link
      className={classNames(className, styles.button, styles.linkButton, {
        [styles.primary]: buttonType === 'primary',
        [styles.secondary]: buttonType === 'secondary',
        [styles.white]: buttonType === 'white',
        [styles.transparent]: buttonType === 'transparent',
        [styles.disabled]: disabled || loading,
      })}
      to={href}
    >
      <span className={styles.confirmButtonContent}>
        {loading && (
          <Loader className={styles.loader} primaryColor="stroke-white" />
        )}
        {Icon && (
          <Icon
            className={classNames(className, styles.linkButtonIcon, {
              [styles.iconPrimary]: buttonType === 'primary',
              [styles.iconSecondary]: buttonType === 'secondary',
              [styles.iconWhite]: buttonType === 'white',
            })}
          />
        )}
        {loading ? loadingText ?? children : children}
      </span>
    </Link>
  );
};
